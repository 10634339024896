import { createGlobalStyle } from "styled-components";
import constants from "./constants";

const GlobalStyle = createGlobalStyle<IAccessibility>`
    ::-moz-selection {
        color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
        background: ${props => props.forecolor || "#3297FD"};
    }

    ::selection {
        color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
        background: ${props => props.forecolor || "#3297FD"};
    }

    .custom-select-list {
        background-color: ${props => props.backcolor};

        .ant-select-item {
            background-color: ${props => props.backcolor};

            .ant-select-item-option-content {
                color: ${props => props.forecolor};
                font-size: 1rem;
                line-height: 1rem;
            }

            &:hover {
                background-color: ${props => props.forecolor};

                .ant-select-item-option-content {
                    color: ${props => props.backcolor};
                }
            }
        }
    }

    .custom-datepicker-dropdown {
        color: ${props => props.forecolor};
        
        a {
            color: ${props => props.forecolor};
        }

        .ant-picker-panel-container {
            background-color: ${props => props.backcolor};

            .ant-picker-header, .ant-picker-footer {
                border-color: ${props => props.forecolor};
            }

            .ant-picker-footer {
                border-bottom: none;
            }
        }

        .ant-picker-cell-inner {
            background-color: ${props => props.backcolor};
            color: ${props => props.forecolor};

            &:hover {
                color: ${props => props.backcolor};
                background-color: ${props => props.forecolor} !important;
            }
        }

        .ant-picker-cell.ant-picker-cell-in-view {
            &:hover {
                .ant-picker-cell-inner {
                    color: ${props => props.backcolor};
                    background-color: ${props => props.forecolor} !important;
                }
            }
        }

        .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
            border-color: ${props => props.forecolor};
        }

        .ant-picker-panel {
            border-bottom: none;
        }

        button {
            &, &:hover {
                color: ${props => props.forecolor};
                background-color: ${props => props.backcolor};
            }
        }

        .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
            color: ${props => props.backcolor};
            background-color: ${props => props.forecolor};
        }
    }

    html {
        font-size: ${props => `${15 * parseFloat(props.sizemode || "1")}px`};
        line-height: ${props => `${15 * parseFloat(props.sizemode || "1") + 10}px`};
    }
    
    .ant-notification-notice {
        background-color: ${props => props.forecolor};
        
        .ant-notification-notice-message {
            color: ${props => props.backcolor};
        }

        .ant-notification-notice-with-icon {
            span {
                color: ${props => props.backcolor};
            }
        }
        
        .ant-notification-close-icon {
            color: ${props => props.backcolor};
        }
    }
    
    .ant-notification-notice-description {
        color: ${props => props.backcolor};
    }
    
    .custom-func-modal {
        .ant-modal-body {
            background-color: ${props => props.forecolor};
            
            .ant-modal-confirm-title,
            .ant-modal-confirm-content {
                color: ${props => props.backcolor};
            }
            
            .ant-btn-primary {
                background-color: ${props => props.backcolor} !important;
                color: ${props => props.forecolor} !important;
            }
        }
    }
    
    .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
        color: ${props => props.backcolor};
    }
    
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.95);
    }

    h2 {
        font-size: ${props => `${20 * parseFloat(props.sizemode || "1")}px`};
        line-height: ${props => `${20 * parseFloat(props.sizemode || "1") + 10}px`};
        color: ${props => props.forecolor || constants.COLOR.TEXT};
    }

    .locations-dropdown {
        width: fit-content !important;
    }
`;

export default GlobalStyle;
