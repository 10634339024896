import CustomResult from "../common/CustomResult";
import {CustomDescription} from "../common";
import {useTranslation} from "react-i18next";

const SuccessfulReferralUploadView: React.VFC = () => {
    const { t } = useTranslation(["referral"]);

    return (
        <>
            <CustomResult
                status="success"
                title={t("referral:REFERRAL_UPLOADED_TITLE")}
            />
            <CustomDescription>
                {t("referral:REFERRAL_UPLOADED_INFO")}
            </CustomDescription>
        </>
    );
}

export default SuccessfulReferralUploadView;