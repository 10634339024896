import { useThemeContext } from "contexts/theme";
import styled from "styled-components";
import { constants } from "utils";
import {CustomDescription} from "./index";
import {BasicProps} from "antd/es/layout/layout";

interface IProps extends BasicProps {
    size?: number;
    title?: string;
}

const StyledSpinner = styled.div<IProps & IAccessibility>`
    text-align: center;

    .loader {
        position: relative;
        margin: 0 auto;
        width: ${props => props.size || 50}px;

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }

    .circular {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
        stroke: ${props => props.forecolor || constants.COLOR.PRIMARY};
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
`;

const CustomSpinner: React.VFC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledSpinner {...theme} {...props}>
            <div className="loader">
                <svg className="circular" viewBox="25 25 50 50">
                    <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                </svg>
            </div>
            {props.title && <CustomDescription>{props.title}</CustomDescription>}
        </StyledSpinner>
    )
}

export default CustomSpinner;
