import { BasicProps } from "antd/lib/layout/layout";
import { LinkButton } from "components/common";
import { useTranslation } from "react-i18next"
import { constants, storage } from "utils";
import dayjs from "dayjs";

const LanguageSelector: React.VFC<BasicProps> = (props) => {
    const { i18n } = useTranslation(["example"]);

    const handleChangeLanguage = (language: string): void => {
        i18n.changeLanguage(language);
        dayjs.locale(language);
        storage.setItem(constants.STORAGE_KEYS.LANGUAGE, language);
    }

    return (
        <div {...props}>
            <LinkButton onClick={() => handleChangeLanguage(constants.LANGUAGES.EN)}>
                EN
            </LinkButton>
            <LinkButton onClick={() => handleChangeLanguage(constants.LANGUAGES.PL)}>
                PL
            </LinkButton>
        </div>
    )
}

export default LanguageSelector
