import React from "react";
import { Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { CustomFuncModal } from "../index";
import { constants } from "../../../utils";
import { useTranslation } from "react-i18next";

interface IProps {
    children: React.ReactNode;
    handleChange: (fileList: UploadFile[]) => void;
}

const ReferralUpload = ({ children, handleChange }: IProps) => {
    const { t } = useTranslation(["common"]);

    const isValidFileExtension = (file: RcFile) => ["image/png", "image/jpg", "image/jpeg", "application/pdf"].some(type => type === file.type); 

    const beforeUpload = (file: RcFile) => {
        if (!isValidFileExtension(file)) {
            CustomFuncModal.displayWarning({
                title: t("common:INVALID_FILE_SEND_TITLE"),
                content: t("common:INVALID_FILE_TYPE")
            })
            return Upload.LIST_IGNORE;
        }

        if (file.size > constants.THRESHOLDS.MAXIMUM_UPLOAD_FILE_SIZE) {
            CustomFuncModal.displayWarning({
                title: t("common:INVALID_FILE_SEND_TITLE"),
                content: t("common:INVALID_FILE_SIZE")
            })
            return Upload.LIST_IGNORE;
        }
        
        return true;
    };

    const dummyRequest = ({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onChange = ({ fileList }: UploadChangeParam) => handleChange(fileList);

    return (
        <Upload
            customRequest={dummyRequest}
            onChange={onChange}
            maxCount={1}
            beforeUpload={beforeUpload}
            showUploadList={false}
            accept=".jpg, .jpeg, .png, .pdf"
        >
            {children}
        </Upload>
    );
};

export default ReferralUpload;
