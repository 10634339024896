export const themeReducer = (state: IAccessibility, action: IThemeAction): IAccessibility => {
    switch (action.type) {
        case "SET_THEME":
            return {
                ...state,
                forecolor: action.payload.forecolor,
                backcolor: action.payload.backcolor
            };
        case "SET_SIZE":
            return {
                ...state,
                sizemode: action.payload.sizemode,
            };
        case "INITIAL_STATE_CHANGE":
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
