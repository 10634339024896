import { Button, ButtonProps } from 'antd';
import { useThemeContext } from 'contexts/theme';
import React from 'react';
import styled from 'styled-components';
import { constants } from 'utils';

interface IProps extends ButtonProps {}

const StyledButton = styled(Button)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;

    &, &:hover, &:active, &:focus {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
    }
`;

const LinkButton: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledButton type="link" size="large" {...theme} {...props}>
            {props.children}
        </StyledButton>
    );
}

export default LinkButton;
