import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { constants } from 'utils';
import { en, pl } from '.';

export const resources = {
    pl: {
        common: pl.common,
        homepage: pl.homepage,
        survey: pl.survey,
        studyDate: pl.studyDate,
        confirmStudyDate: pl.confirmStudyDate,
        referral: pl.referral,
        loading: pl.loading,
        personalData: pl.personalData,
        queue: pl.queue,
        phoneContact: pl.phoneContact,
        successfulRegistration: pl.successfulRegistration,
        successfulPayment: pl.successfulPayment,
        failedPayment: pl.failedPayment,
        invitation: pl.invitation,
    },
    en: {
        common: en.common,
        homepage: en.homepage,
        survey: en.survey,
        studyDate: en.studyDate,
        confirmStudyDate: en.confirmStudyDate,
        referral: en.referral,
        loading: en.loading,
        personalData: en.personalData,
        queue: en.queue,
        phoneContact: en.phoneContact,
        successfulRegistration: en.successfulRegistration,
        successfulPayment: en.successfulPayment,
        failedPayment: en.failedPayment,
        invitation: en.invitation,
    },
} as const;

i18n.use(initReactI18next).init({
    resources,
    defaultNS: 'common',
    ns: [
        'personalData',
        'common',
        'homepage',
        'survey',
        'studyDate',
        'confirmStudyDate',
        'referral',
        'loading',
        'phoneContact',
        'successfulRegistration',
        'invitation',
    ],
    lng: constants.LANGUAGES.PL,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    }
});

export default i18n;
