import React, { useContext } from "react";

interface AppVersionContext {
    currentVersion: string;
}

const VersionContext = React.createContext<AppVersionContext>({
    currentVersion: `v. 2.38.d5bf3204, 19.03.2024`
});

export const useAppVersion = () => useContext(VersionContext);
