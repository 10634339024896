import { BasicProps } from "antd/lib/layout/layout";
import { LinkButton } from "components/common";
import { useThemeContext } from "contexts/theme";
import { useTranslation } from "react-i18next";
import { constants, storage } from "utils"
import { AccessibilityButton } from "./";

const AccessibilityButtons: React.VFC<BasicProps> = (props) => {
    const { dispatch } = useThemeContext();
    const { t } = useTranslation(["common"]);

    const changeContrast = (forecolor: AccessibilityMode, backcolor: AccessibilityMode): void => {
        storage.setItem(constants.STORAGE_KEYS.CONTRAST.FORECOLOR, forecolor);
        storage.setItem(constants.STORAGE_KEYS.CONTRAST.BACKCOLOR, backcolor);

        dispatch({
            type: "SET_THEME",
            payload: {
                forecolor,
                backcolor,
                sizemode: ""
            }
        });
    }

    const changeSize = (sizemode: AccessibilitySize): void => {
        storage.setItem(constants.STORAGE_KEYS.SIZE, sizemode);

        dispatch({
            type: "SET_SIZE",
            payload: {
                forecolor: "",
                backcolor: "",
                sizemode
            }
        });
    }

    const resetAccesibilityMode = (): void => {
        changeSize("1");
        changeContrast("", "");
    }

    return (
        <div {...props}>
            <div>
                <LinkButton style={{ fontSize: 15 }} onClick={() => changeSize("1")}>
                    A
                </LinkButton>
                <LinkButton style={{ fontSize: 18 }} onClick={() => changeSize("1.2")}>
                    A+
                </LinkButton>
                <LinkButton style={{ fontSize: 22 }} onClick={() => changeSize("1.6")}>
                    A++
                </LinkButton>
            </div>
            <div>
                <AccessibilityButton 
                    onClick={() => changeContrast("white", "black")}
                    forecolor="white"
                    backcolor="black"
                    sizemode=""
                    bordered="true"
                >
                    A
                </AccessibilityButton>
                <AccessibilityButton 
                    onClick={() => changeContrast("black", "white")}
                    forecolor="black"
                    backcolor="white"
                    sizemode=""
                    bordered="true"
                >
                    A
                </AccessibilityButton>
                <AccessibilityButton 
                    onClick={() => changeContrast("yellow", "black")}
                    forecolor="yellow"
                    backcolor="black"
                    sizemode=""
                    bordered="true"
                >
                    A
                </AccessibilityButton>
                <AccessibilityButton 
                    onClick={() => changeContrast("black", "yellow")}
                    forecolor="black"
                    backcolor="yellow"
                    sizemode=""
                    bordered="true"
                >
                    A
                </AccessibilityButton>
            </div>
            <div>
                <LinkButton onClick={resetAccesibilityMode}>
                    {t("common:RESET_ACCESSIBILITY")}
                </LinkButton>
            </div>
        </div>
    );
}

export default AccessibilityButtons;
