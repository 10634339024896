import { CustomDescription, CustomSpinner, Header } from "components/common";
import { useTranslation } from "react-i18next";
import styled from "styled-components"

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
`;

type IProps = {
    displayAdditionalInformation: false;
    peoplesInQueue?: number;
    maximumWaitingTime?: string;
    children?: React.ReactNode;
} | {
    displayAdditionalInformation: true;
    peoplesInQueue: number;
    maximumWaitingTime: string;
    children?: React.ReactNode;
}

export default function QueueView({ displayAdditionalInformation, peoplesInQueue, maximumWaitingTime, children }: IProps) {
    const { t } = useTranslation(["loading"]);

    return (
        <StyledContainer>
            <CustomSpinner size={125} />
            <Header style={{ marginTop: 25 }}>
                {t("loading:TITLE")}
            </Header>
            {displayAdditionalInformation && (
                <CustomDescription>
                    {t("loading:PATIENTS_IN_QUEUE")}: <b>{peoplesInQueue}.</b> {t("loading:MAXIMUM_WAITING_TIME")}: <b>{maximumWaitingTime}</b>
                </CustomDescription>
            )}
            <div style={{ marginTop: 25 }}>
                {children}
            </div>
        </StyledContainer>
    );
}
