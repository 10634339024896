import { Form, FormItemProps } from 'antd'
import { useThemeContext } from 'contexts/theme';
import React from 'react'
import styled from 'styled-components'
import { constants } from 'utils';

interface IProps extends FormItemProps {}

const StyledFormItem = styled(Form.Item)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;

    .ant-form-item-label label {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        font-size: 1rem;
        line-height: 1rem;
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: ${props => props.forecolor};
    }

    &.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
        background-color: ${props => props.backcolor} !important;
        border-color: ${props => props.forecolor};
    }

    .ant-form-item-explain.ant-form-item-explain-error {
        color: ${props => props.forecolor};
    }
`;

const CustomFormItem: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledFormItem {...theme} {...props}>
            {props.children}
        </StyledFormItem>
    );
}

export default CustomFormItem;
