import { Switch, SwitchProps } from "antd";
import { useThemeContext } from "contexts/theme";
import styled from "styled-components";
import { constants } from "utils";

const StyledSwitch = styled(Switch)<IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;
    border: ${props => props.backcolor ? `1px solid ${props.forecolor}` : "none"};

    &.ant-switch-checked {
        background-color: ${props => props.forecolor || constants.COLOR.PRIMARY};
        .ant-switch-handle {
            &::before {
                background-color: ${props => props.backcolor || "#FFF"};
            }
        }
    }
`;

interface IProps extends SwitchProps {}

const CustomSwitch = (props: IProps) => {
    const { theme } = useThemeContext();

    return (
        <StyledSwitch {...theme} {...props} />
    );
};

export default CustomSwitch;
