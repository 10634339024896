import { Radio, RadioProps } from "antd";
import { useThemeContext } from "contexts/theme";
import React from "react";
import styled from "styled-components";
import { constants } from "utils";

interface IProps extends RadioProps {}

const StyledRadio = styled(Radio)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;
    white-space: pre-line;
    margin: 5px 0px;

    &,
    span {
        font-size: 1rem;
        line-height: 1rem;
        white-space: pre-line;
    }

    &,
    &:hover,
    &:active,
    &:focus {
        display: block;
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        outline: none;
        font-weight: normal;

        .ant-radio-checked .ant-radio-inner {
            border-color: ${props => props.forecolor || constants.COLOR.PRIMARY};
        }

        .ant-radio-inner {
            background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
            border-color: ${props => props.forecolor};
        }

        .ant-radio-inner::after {
            background-color: ${props => props.forecolor || constants.COLOR.PRIMARY};
        }
    }

    @media screen and (max-width: 992px) {
        margin: 10px 0px;
        width: 100%;
    }
`;

const CustomRadio: React.FC<IProps> = props => {
    const { theme } = useThemeContext();

    return (
        <StyledRadio {...theme} {...props}>
            {props.children}
        </StyledRadio>
    );
};

export default CustomRadio;
