import { LayoutProps, Layout } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import AccessibilityContainer from "components/features/accessibility/AccessibilityContainer";
import { useThemeContext } from "contexts/theme";
import { useAppVersion } from "../../contexts/appVersion/AppVersionContext";
import styled from "styled-components";
import { constants, GlobalStyle, Routes } from "utils";
import logo from "../../assets/images/logo_geneva_front.png";
import { AppVersion } from "../common";
import { useTranslation } from "react-i18next";

interface IProps extends LayoutProps {}

const StyledLayout = styled(Layout)<IProps & IAccessibility>`
    min-height: 100vh;
    padding: 50px;

    .app-version {
        display: flex;
        justify-content: flex-end;
        margin: 0 20px;
    }

    &,
    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
        background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};

        &,
        a {
            color: ${props => props.forecolor || constants.COLOR.TEXT};
        }
    }

    .ant-layout-header,
    .ant-layout-content,
    .ant-layout-footer {
        width: 100%;
        max-width: 1800px;
        margin: 0px auto;
        padding: 35px 0px;
    }

    .ant-layout-header {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: auto;
    }

    .ant-layout-content {
        margin-top: 50px;
        position: relative;
    }

    @media screen and (max-width: 992px) {
        padding: 15px;

        .ant-layout-header {
            flex-direction: column;
        }

        .ant-layout-footer {
            display: flex;
            flex-direction: column;
            padding: 35px 0px;
        }
    }
`;

const MainLayout: React.FC<IProps> = props => {
    const { theme } = useThemeContext();
    const { currentVersion } = useAppVersion();
    const { t } = useTranslation(["common"]);

    return (
        <StyledLayout {...theme} {...props}>
            <GlobalStyle {...theme} />
            <Header>
                <img src={logo} alt="Geneva Trust Logo" />
                <AccessibilityContainer />
            </Header>
            <AppVersion version={currentVersion} />
            <Content>{props.children}</Content>
            <Footer>
                {/* zakomentowana sekcja nawigacji która pozwala w debugowaniu, TODO usunąć gdy nie będzie potrzebna */}
                {/* <Link to={Routes.HOME_PAGE} style={{ margin: 15 }}>
                    Start
                </Link>
                <Link to={Routes.FORM_VIEW} style={{ margin: 15 }}>
                    Dane osobowe
                </Link>
                <Link to={Routes.QUESTIONNAIRE} style={{ margin: 15 }}>
                    Ankieta
                </Link>
                <Link to={Routes.STUDY_DATE} style={{ margin: 15 }}>
                    Termin
                </Link>
                <Link to={Routes.CONFIRM_STUDY_DATE} style={{ margin: 15 }}>
                    Potwierdzenie
                </Link>
                <Link to={Routes.REFERRAL} style={{ margin: 15 }}>
                    Skan skierowania
                </Link>
                <Link to={Routes.QUEUE} style={{ margin: 15 }}>
                    Oczekiwanie
                </Link>
                <Link to={Routes.SUCCESSFUL_PATIENT_REGISTRATION} style={{ margin: 15 }}>
                    Post-rejestracja
                </Link>
                <Link to={Routes.SUCCESSFUL_PATIENT_PAYMENT} style={{ margin: 15 }}>
                    Płatność-sukces
                </Link>
                <Link to={Routes.FAILED_PATIENT_PAYMENT} style={{ margin: 15 }}>
                    Płatność-niepowodzenie
                </Link> */}
                <a href="regulamin.pdf" target="_blank">
                    {t("common:TERMS_AND_CONDITIONS")}
                </a>
            </Footer>
        </StyledLayout>
    );
};

export default MainLayout;
