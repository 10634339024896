import { Col, Row } from "antd";
import { CustomDescription, Header, PrimaryButton } from "components/common";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "utils";
import queryString from "querystring";
import { useInvitation, useInvitationLink } from "hooks";
import InvitationForm from "components/features/invitation/InvitationForm";
import { getInvitationLink } from "utils/invitation";

const invitation = getInvitationLink();

export default function HomepageView() {
    const { t } = useTranslation(["common", "homepage"]);
    const history = useHistory();
    useInvitationLink();

    const { loading, verify } = useInvitation();

    const { payment } = queryString.parse(window.location.search.slice(1));

    if (payment === "success") {
        history.push(Routes.SUCCESSFUL_PATIENT_PAYMENT);
    }

    if (payment === "failed") {
        history.push(Routes.FAILED_PATIENT_PAYMENT);
    }

    return (
        <Row gutter={[100, 50]}>
            <Col md={12}>
                <Header>{t("homepage:WELCOME_HEADER")}</Header>
                <CustomDescription>{t("homepage:DESCRIPTION")}</CustomDescription>
                <Row style={{ marginTop: 40 }}>
                    <Col span={24}>
                        <Link to={Routes.FORM_VIEW}>
                            <PrimaryButton>{t("homepage:START_REGISTRATION")}</PrimaryButton>
                        </Link>
                    </Col>
                </Row>
            </Col>

            <Col md={12} style={{ borderLeft: "1px solid #ddd" }}>
                <Header>{t("homepage:INVITATION_HEADER")}</Header>
                <CustomDescription>{t("homepage:INVITATION_DESCRIPTION")}</CustomDescription>

                <InvitationForm
                    initialValues={{ accessCode: invitation }}
                    onFinish={verify}
                    buttons={
                        <PrimaryButton style={{ marginTop: 25 }} loading={loading} htmlType="submit">
                            {t("homepage:SUBMIT_INVITATION")}
                        </PrimaryButton>
                    }
                />
            </Col>
        </Row>
    );
}
