import {Modal, ModalProps} from "antd";
import { useThemeContext } from "contexts/theme";
import styled from "styled-components";

interface IProps extends ModalProps {
    children?: React.ReactNode | React.ReactNode[]
}

const StyledModal = styled(Modal)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;
    
    .ant-modal-content, .ant-modal-header {
        color: ${props => props.forecolor};
        background-color: ${props => props.backcolor};
    }
    
    .ant-modal-header, .ant-modal-footer {
        border: none;
    }
    
    .ant-modal-title {
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: ${props => props.forecolor};
    }
`;

const CustomSwitch = (props: IProps) => {
    const { theme } = useThemeContext();

    return (
        <StyledModal {...theme} {...props}>
            {props.children}
        </StyledModal>
    );
};

export default CustomSwitch;
