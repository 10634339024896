import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import { useThemeContext } from 'contexts/theme';
import React from 'react';
import styled from 'styled-components';
import { constants } from 'utils';

interface IProps extends CheckboxProps {}

const StyledCheckbox = styled(Checkbox)<IProps & IAccessibility>`
    &, span {
        font-size: 1rem;
        line-height: 1rem;
        white-space: pre-line;
    }

    &, &:hover, &:active, &:focus {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        outline: none;

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: ${props => props.backcolor || constants.COLOR.PRIMARY};
                border-color: ${props => props.forecolor || constants.COLOR.PRIMARY};

                &:after {
                    border-color: ${props => props.forecolor || constants.COLOR.BACKGROUND};
                }
            }
        }

        .ant-checkbox-inner {
            background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
            border-color: ${props => props.forecolor};
        }

        .ant-checkbox-checked::after {
            border: none;
        }
    }

    @media screen and (max-width: 992px) {
        margin: 10px 0px;
        width: 100%;
    }
`;

const CustomCheckbox: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledCheckbox {...theme} {...props}>
            {props.children}
        </StyledCheckbox>
    );
}

export default CustomCheckbox;
