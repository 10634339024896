import { useState } from "react"
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { interceptApiError } from "utils/interceptors";
import useSuccessInvitation from "./useSuccessInvitation";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { constants } from "utils";
import * as dal from 'dal';

const useInvitation = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const onSuccess = useSuccessInvitation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const verify = (request: VerifyInvitation) => {
    setLoading(true);

    executeRecaptcha?.(constants.CAPTCHA_ACTIONS.VERIFY_INVITATION)
      .then(token => {
        dal.invitation
          .verifyInvitation(request, token)
          .then(onSuccess)
          .catch(e => interceptApiError(e, t, history))
          .finally(() => setLoading(false));
      })
      .catch(() => setLoading(false));
  };

  return { loading, verify };
}

export default useInvitation;