import { AutoComplete, AutoCompleteProps } from "antd";
import styled from "styled-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useThrottleFn } from "ahooks";
import * as dal from "../../../dal";

interface ITerritory {
    id: number;
    rodz: number;
    name: string;
    nazdod: string;
    woj?: number;
    pow?: number;
    powName?: string;
    communityName?: string;
    gmi?: number;
    province?: string;
    nazwa?: string;
}

const getTerritorySearchResultArrayDividedIntoAdministrativeParts = (result: ITerritory, lang: string) => {
    const parts = [];
    let wojpowPart = "";

    if (result.province) wojpowPart += lang === "pl" ? `woj. ${result.province}, ` : `prov. ${result.province}, `;
    if (result.powName) wojpowPart += lang === "pl" ? `pow. ${result.powName}, ` : `dist. ${result.powName}, `;
    if (result.communityName) wojpowPart += lang === "pl" ? `gm. ${result.communityName}` : `comm. ${result.communityName}`;
    parts.push(wojpowPart);

    if (result.nazdod) parts.push(lang === "pl" ? `rodz. ${result.nazdod}` : `type ${result.nazdod}`);

    return parts;
};

type Props = AutoCompleteProps & {
    districtPow: number;
    communityGmi: number;
    provinceTid: number;
    city: string;
    setCity: (v: string) => void;
    label?: string;
};

const StyledLabel = styled.label`
    top: 0;
    display: block;
    font-size: 1rem;
    color: #9b9b9b;
    margin-top: 10px;
`;

const SearchCityInput: React.VFC<Props> = props => {
    const { i18n } = useTranslation("common");

    const [foundLocations, setFoundLocations] = useState<ITerritory[]>([]);

    const { districtPow, communityGmi, provinceTid, city, setCity, label } = props;

    const { run: handleFetchTerritories } = useThrottleFn(
        () => {
            if (city)
                dal.territory
                    .findCityByTerritory({ provinceTid, districtPow, communityGmi, name: city })
                    .then(res => setFoundLocations(res.data))
                    .catch(console.log);
        },
        { wait: 1000 }
    );

    const handleOnSelectLocation = (id: number) => {
        const location = foundLocations.find(loc => loc.id === id);
        if (location) {
            setFoundLocations([]);
            setCity(location.name);
        }
    };

    return (
        <>
            {label && <StyledLabel>{label}</StyledLabel>}
            <AutoComplete
                style={{ width: "100%" }}
                open={city?.length > 0 && foundLocations?.length > 0}
                dropdownClassName="locations-dropdown"
                onChange={setCity}
                onKeyDown={handleFetchTerritories}
                onSelect={(v, o) => handleOnSelectLocation(+v)}
                value={city}
                disabled={!provinceTid || !districtPow || !communityGmi}
            >
                {foundLocations.map((loc: ITerritory) => (
                    <AutoComplete.Option key={loc.id} value={loc.id}>
                        <p style={{ marginBottom: 0 }}>
                            <strong>{loc.name}</strong>
                        </p>
                        {getTerritorySearchResultArrayDividedIntoAdministrativeParts(loc, i18n.language).map(part => (
                            <p style={{ marginBottom: 0 }}>{part}</p>
                        ))}
                    </AutoComplete.Option>
                ))}
            </AutoComplete>
        </>
    );
};

export default SearchCityInput;
