import { Col, Row, Form, notification, Radio, Space } from "antd";
import { CustomCheckbox, CustomDescription, CustomFormItem, CustomRadio, Header, PrimaryButton, SecondaryButton } from "components/common";
import { useRegistrationContext } from "contexts/registration/registrationContext";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "utils";
import { hasPhoneNumber } from "utils/helpers";
import { useEffect, useState } from "react";

export default function QuestionarieView() {
    const [form] = Form.useForm();
    const { data, dispatch } = useRegistrationContext();
    const { push } = useHistory();
    const { t } = useTranslation(["common", "survey"]);
    const [optionChanged, setOptionChanged] = useState(false);
    const [studyType, setStudyType] = useState<"first" | "next">();

    const handleOptionChanged = () => {
        setOptionChanged(!optionChanged);
    };

    const onFinish = (values: IQuestionnaire) => {
        dispatch({ type: "SET_QUESTIONNAIRE", payload: values });
        push(Routes.STUDY_DATE);
    };

    return (
        <Form initialValues={{ ...data.questionnaire, studyType: undefined }} form={form} onFinish={onFinish} onChange={handleOptionChanged}>
            <Header>{t("survey:TITLE")}</Header>
            <CustomDescription>{t("survey:DESCRIPTION")}</CustomDescription>
            <Row style={{ marginTop: 50 }}>
                <CustomFormItem
                    name="studyType"
                    rules={[
                        {
                            required: true,
                            message: t("common:REQUIRED_FORM_FIELD")
                        }
                    ]}
                >
                    <Radio.Group onChange={e => setStudyType(e.target.value)}>
                        <Space direction="vertical">
                            <CustomRadio value={"initial"}>
                                {data?.type?.type === "nfz" ? t("survey:INITIAL_STUDY_PREVENTIVE") : t("survey:INITIAL_STUDY_CLINICAL")}
                            </CustomRadio>
                            <CustomRadio value={"next"}>
                                {data?.type?.type === "nfz" ? t("survey:NEXT_STUDY_PREVENTIVE") : t("survey:NEXT_STUDY_CLINICAL")}
                            </CustomRadio>
                        </Space>
                    </Radio.Group>
                </CustomFormItem>
                {studyType && (
                    <>
                        <Col span={24}>
                            <CustomFormItem valuePropName="checked" name="cancerInFamily">
                                <CustomCheckbox>{t("survey:CANCER_IN_FAMILY")}</CustomCheckbox>
                            </CustomFormItem>
                        </Col>
                        <Col span={24}>
                            <CustomFormItem valuePropName="checked" name="breastAilment">
                                <CustomCheckbox>{t("survey:BREAST_AILMENT")}</CustomCheckbox>
                            </CustomFormItem>
                        </Col>
                        <Col span={24}>
                            <CustomFormItem valuePropName="checked" name="invalidUsg">
                                <CustomCheckbox>{t("survey:INVALID_USG")}</CustomCheckbox>
                            </CustomFormItem>
                        </Col>
                        <Col span={24}>
                            <CustomFormItem valuePropName="checked" name="bloodyWart">
                                <CustomCheckbox>{t("survey:BLOODY_WART")}</CustomCheckbox>
                            </CustomFormItem>
                        </Col>
                        <Col span={24}>
                            <CustomFormItem valuePropName="checked" name="retractionOfSkin">
                                <CustomCheckbox>{t("survey:RETRACTION_OF_SKIN")}</CustomCheckbox>
                            </CustomFormItem>
                        </Col>
                    </>
                )}
            </Row>
            <Row style={{ marginTop: 50 }}>
                <Col span={24}>
                    <Link to={Routes.FORM_VIEW}>
                        <SecondaryButton style={{ marginRight: 25 }}>{t("common:BACK_AND_CHANGE_PERSONAL_DATA")}</SecondaryButton>
                    </Link>
                    {hasPhoneNumber(data.personalData?.phone) && (
                        <Link to={Routes.PHONE_CONTACT}>
                            <SecondaryButton style={{ marginRight: 25 }}>{t("common:REQUEST_PHONE_CALL")}</SecondaryButton>
                        </Link>
                    )}
                    <PrimaryButton
                        htmlType="submit"
                        // disabled={isSubmitButtonDisabled}
                        disabled={studyType == undefined}
                    >
                        {t("survey:SELECT_STUDY_DATE")}
                    </PrimaryButton>
                </Col>
            </Row>
        </Form>
    );
}
