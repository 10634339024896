import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Col, Form, Radio, Row } from "antd";
import {
    CustomDescription,
    CustomFormItem,
    CustomFuncModal,
    CustomModal,
    CustomRadio,
    CustomSpinner,
    Header,
    LinkButton,
    PrimaryButton,
    SecondaryButton
} from "components/common";
import { StudyDateCounter } from "components/features/counter";
import { useRegistrationContext } from "contexts/registration/registrationContext";
import * as dal from "dal";
import { capitalizeFirstLetter, getDate, getDayName, hasPhoneNumber } from "../../utils/helpers";
import { constants, Routes } from "utils";
import { PatientInformation } from "../features/studyView";
import { useThemeContext } from "../../contexts/theme";
import { interceptApiError } from "../../utils/interceptors";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import dayjs, { Dayjs } from "dayjs";

export default function StudyDateView() {
    const history = useHistory();
    const { data, dispatch } = useRegistrationContext();
    const { t } = useTranslation(["common", "studyDate"]);
    const { theme } = useThemeContext();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const { pesel, passport, idCard } = data?.personalData;

    const [render, setRender] = useState(false);
    const [dateDeadline, setDateDeadline] = useState<Dayjs | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCheckingIfPatientRegistered, setIsCheckingIfPatientRegistered] = useState(false);
    const [displaySessionTimeoutModal, setSessionTimeoutModal] = useState(false);
    const [screeningDates, setScreeningDates] = useState<IScreening[]>([]);
    const [patient, setPatient] = useState<IPatient | null>(null);
    const [selectedRegistration, setRegistration] = useState<ICampaignRegistration | null>(null);
    const [modalConfirm, setModalConfirm] = useState<any>();

    const submitButtonDisabled = !selectedRegistration || isCheckingIfPatientRegistered;

    const redirectToFormView = () => {
        history.push(Routes.FORM_VIEW);
    };

    const showResult = (patientIsRegistered: boolean): void => {
        if (patientIsRegistered) {
            setIsCheckingIfPatientRegistered(false);
            CustomFuncModal.displayConfirm({
                title: t("studyDate:PATIENT_ALREADY_REGISTERED"),
                content: t("studyDate:PATIENT_ALREADY_REGISTERED_INFO"),
                cancelText: t("studyDate:PATIENT_ALREADY_REGISTERED_SUBMIT"),
                okButtonProps: { style: { display: "none" } }
            });
            return;
        }
        selectCampaignRegistrationDate();
    };

    const checkIfPatientAlreadyIsAssignedToTheCampaign = (): void => {
        const campaignDayId = String(selectedRegistration?.campaignRegistration.campaignDayId);
        setIsCheckingIfPatientRegistered(true);

        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.FIND_DATES)
            .then(token => {
                dal.campaign
                    .findPatientScreeningsDates(campaignDayId, pesel, passport, idCard, token)
                    .then(res => showResult(res.data))
                    .catch(error => interceptApiError(error, t, history))
                    .finally(() => setIsLoading(false));
            })
            .catch(() => setIsLoading(false));
    };

    const handleSuccessfulDataLoad = (date: { patient: IPatient; screeningDates: IScreening[] }) => {
        const {
            personalData: { nfz }
        } = data || {};

        if (date.patient.externalApiError && nfz) {
            CustomFuncModal.displayConfirm({
                title: t("studyDate:NO_EXTERNAL_API_CONNECTION"),
                content: t("studyDate:NO_EXTERNAL_API_INFO"),
                okText: t("studyDate:CHECK_AGAIN"),
                cancelText: t("studyDate:VIEW_DATES"),
                onOk: () => findDates()
            });
        }

        if (date.patient.unknownError && nfz) {
            CustomFuncModal.displayConfirm({
                title: t("studyDate:EXTERNAL_API_ERROR"),
                content: t("studyDate:NO_EXTERNAL_API_INFO"),
                okText: t("studyDate:CHECK_AGAIN"),
                cancelText: t("studyDate:VIEW_DATES"),
                onOk: () => findDates()
            });
        }

        setPatient(date.patient);
        setScreeningDates(date.screeningDates);
    };

    const findDates = () => {
        const {
            personalData: { locations, pesel }
        } = data || {};

        const paidResearch = data.type.type === "paid";
        const nfz = data.type.type === "nfz";

        setIsLoading(true);
        setDateDeadline(dayjs().add(constants.TIMEOUTS.MAXIMUM_TIME_FOR_SELECT_STUDY_DATE, "seconds"));

        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.FIND_DATES)
            .then(token => {
                dal.screening
                    .findScreeningsDates(locations, pesel, paidResearch, nfz, token)
                    .then(res => res.data)
                    .then(handleSuccessfulDataLoad)
                    .catch(error => interceptApiError(error, t, history))
                    .finally(() => setIsLoading(false));
            })
            .catch(() => setIsLoading(false));
    };

    useEffect(() => {
        const timer = setInterval(() => setRender(prev => !prev), 1000);

        return () => clearInterval(timer);
    }, []);

    const getDeadlineDifference = () => dayjs(dateDeadline).diff(dayjs(), "seconds");

    useEffect(() => {
        const time = getDeadlineDifference();

        if (dateDeadline === null || isNaN(time)) return;

        modalConfirm?.update({
            content: (
                <>
                    {t("studyDate:PAID_DATE_CONFIRMATION_INFO")}
                    <StudyDateCounter time={getDeadlineDifference() > 0 ? getDeadlineDifference() : 0} allowColorizeTime style={{ marginTop: 20 }}>
                        {t("studyDate:TIME_FOR_DECISION")}:
                    </StudyDateCounter>
                </>
            )
        });

        if (time <= 0) {
            modalConfirm?.destroy();
            setSessionTimeoutModal(true);
        }

        if (time <= -10) {
            redirectToFormView();
        }
    }, [render]);

    useEffect(() => {
        findDates();
    }, []);

    const selectCampaignRegistrationDate = () => {
        if (!selectedRegistration) return;

        setIsCheckingIfPatientRegistered(false);

        dispatch({
            type: "SET_CAMPAIGN_REGISTRATION",
            payload: selectedRegistration
        });

        history.push(Routes.CONFIRM_STUDY_DATE);
    };

    const confirmRegistrationDate = () => {
        if (!selectedRegistration) return;

        if (selectedRegistration?.funding?.isPaid) {
            const modal = CustomFuncModal.displayConfirm({
                title: t("studyDate:PAID_DATE_CONFIRMATION"),
                content: t("studyDate:PAID_DATE_CONFIRMATION_INFO"),
                okText: t("studyDate:PAID_DATE_CONFIRMATION_SUBMIT"),
                cancelText: t("studyDate:PAID_DATE_CONFIRMATION_CANCEL"),
                onOk: checkIfPatientAlreadyIsAssignedToTheCampaign
            });
            setModalConfirm(modal);
        } else {
            checkIfPatientAlreadyIsAssignedToTheCampaign();
        }
    };

    if (isLoading) {
        return (
            <CustomSpinner
                size={90}
                style={{
                    margin: "75px 0px"
                }}
            />
        );
    }

    const availableDates = screeningDates.reduce(
        (prev, current) => prev + (current?.districtRegistration?.filter(d => d)?.length || 0) + (current?.communeRegistrations?.filter(c => c)?.length || 0),
        0
    );

    const {
        personalData: { nfz }
    } = data || {};

    return (
        <>
            <CustomModal
                visible={displaySessionTimeoutModal}
                title="Twoja sesja wygasła"
                width={800}
                closable={false}
                footer={<PrimaryButton onClick={redirectToFormView}>{t("studyDate:SESSION_TIMEOUT_TITLE")}</PrimaryButton>}
                onCancel={redirectToFormView}
            >
                <CustomDescription>{t("studyDate:SESSION_TIMEOUT_DESCRIPTION")}</CustomDescription>
            </CustomModal>
            {nfz && (
                <PatientInformation
                    patient={patient}
                    style={{
                        marginBottom: 50
                    }}
                />
            )}
            <Row align="middle">
                <Col md={16} span={24}>
                    <Header>{t("studyDate:TITLE")}</Header>
                    <CustomDescription>{t("studyDate:DESCRIPTION")}</CustomDescription>
                </Col>
                <Col md={8} span={24}>
                    <StudyDateCounter time={getDeadlineDifference() > 0 ? getDeadlineDifference() : 0} allowColorizeTime>
                        {t("studyDate:TIME_FOR_DECISION")}:
                    </StudyDateCounter>
                </Col>
            </Row>
            <Form layout="vertical">
                {availableDates === 0 && (
                    <CustomDescription>
                        {t("studyDate:NOT_FOUND_DATES")}{" "}
                        <LinkButton style={{ padding: 0, color: theme.forecolor || constants.COLOR.PRIMARY, fontWeight: "bold" }} onClick={findDates}>
                            {t("studyDate:NOT_FOUND_CLICK_HERE")}
                        </LinkButton>
                        {t("studyDate:NOT_FOUND_DATES_ADDITIONAL_INFO")}
                    </CustomDescription>
                )}
                {screeningDates
                    .filter(s => s.communeRegistrations.some(c => c) || s.districtRegistration.some(d => d))
                    .map(d => (
                        <CustomFormItem
                            label={`${t(d.funding?.isPaid ? "studyDate:PAID_DATES" : "studyDate:REIMBURSER_DATES")} ${
                                d.funding?.isPaid ? `${(d?.funding?.price || 0).toFixed(2)} PLN` : d.funding?.name
                            }:`}
                            style={{ fontWeight: "bold", marginTop: 50 }}
                        >
                            <Radio.Group value={selectedRegistration?.campaignRegistration?.registrationId || 0}>
                                {d.districtRegistration
                                    ?.filter(d => d)
                                    ?.map(registration => (
                                        <CustomRadio
                                            value={registration.registrationId}
                                            onClick={() => setRegistration({ funding: d.funding, campaignRegistration: registration })}
                                        >
                                            {getDate(registration?.date || "")} ({capitalizeFirstLetter(getDayName(registration?.date || ""))}){" "}
                                            {registration?.startAt?.hours}:
                                            {registration?.startAt?.minutes < 10 ? `0${registration?.startAt?.minutes}` : registration?.startAt?.minutes} -{" "}
                                            {t("studyDate:LOCATION")}: {capitalizeFirstLetter(registration?.voivodeship || "")},{" "}
                                            {capitalizeFirstLetter(registration?.community || "")}, {registration?.shortName}
                                        </CustomRadio>
                                    ))}
                                {d.communeRegistrations
                                    ?.filter(d => d)
                                    ?.map(registration => (
                                        <CustomRadio
                                            value={registration.registrationId}
                                            onClick={() => setRegistration({ funding: d.funding, campaignRegistration: registration })}
                                        >
                                            {getDate(registration?.date || "")} ({capitalizeFirstLetter(getDayName(registration?.date || ""))}){" "}
                                            {registration?.startAt?.hours}:
                                            {registration?.startAt?.minutes < 10 ? `0${registration?.startAt?.minutes}` : registration?.startAt?.minutes} -{" "}
                                            {t("studyDate:LOCATION")}: {capitalizeFirstLetter(registration?.voivodeship || "")},{" "}
                                            {capitalizeFirstLetter(registration?.community || "")}, {registration?.shortName}
                                        </CustomRadio>
                                    ))}
                            </Radio.Group>
                        </CustomFormItem>
                    ))}
            </Form>
            <Row style={{ marginTop: 50 }}>
                <Col span={24}>
                    <Link to={Routes.FORM_VIEW}>
                        <SecondaryButton style={{ marginRight: 25 }}>{t("common:BACK_AND_CHANGE_PERSONAL_DATA")}</SecondaryButton>
                    </Link>
                    {hasPhoneNumber(data.personalData?.phone) && (
                        <Link to={Routes.PHONE_CONTACT}>
                            <SecondaryButton style={{ marginRight: 25 }}>{t("common:REQUEST_PHONE_CALL")}</SecondaryButton>
                        </Link>
                    )}
                    <PrimaryButton htmlType="submit" disabled={submitButtonDisabled} onClick={confirmRegistrationDate} loading={isCheckingIfPatientRegistered}>
                        {t("studyDate:CONFIRM_DATE")}
                    </PrimaryButton>
                </Col>
            </Row>
        </>
    );
}
