import React, { useContext, useReducer } from "react";

const defaultPersonalData = {
    email: "",
    firstName: "",
    idCard: "",
    lastName: "",
    locations: [],
    nfz: true,
    paidResearch: true,
    passport: "",
    pesel: "",
    phone: "",
    policy: "",
    referral: true,
    insurance: ""
};

const defaultQuestionnaire = {
    bloodyWart: false,
    breastAilment: false,
    cancerInFamily: false,
    initialStudy: false,
    invalidUsg: false,
    retractionOfSkin: false
};

const defaultType: IType = {
    type: "nfz",
    referral: false
};

const data: IRegistrationData = {
    personalData: defaultPersonalData,
    selectedRegistration: null,
    homePage: null,
    questionnaire: defaultQuestionnaire,
    referralId: null,
    type: defaultType
};

type RegistrationStateProviderProps = {
    children: React.ReactChild | React.ReactChild[];
};

const RegistrationContext = React.createContext<IRegistrationStore>({
    data,
    dispatch: () => {}
});

const registrationReducer = (state: IRegistrationData, action: IRegistrationAction): IRegistrationData => {
    switch (action.type) {
        case "SET_PERSONAL_DATA": {
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    ...action.payload
                }
            };
        }
        case "SET_LOCATIONS": {
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    locations: action.payload
                }
            };
        }
        case "SET_PHONE_NUMBER": {
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    phone: action.payload
                }
            };
        }
        case "SET_CAMPAIGN_REGISTRATION": {
            return {
                ...state,
                selectedRegistration: action.payload
            };
        }
        case "SET_QUESTIONNAIRE": {
            return {
                ...state,
                questionnaire: action.payload
            };
        }
        case "SET_REFERRAL": {
            return {
                ...state,
                referralId: action.payload
            };
        }
        case "SET_TYPE": {
            return {
                ...state,
                type: action.payload
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`);
        }
    }
};

export const useRegistrationContext = () => useContext(RegistrationContext);

export const RegistrationProvider: React.FC<RegistrationStateProviderProps> = ({ children }: RegistrationStateProviderProps) => {
    const [registration, dispatch] = useReducer(registrationReducer, data);

    return <RegistrationContext.Provider value={{ data: registration, dispatch }}>{children}</RegistrationContext.Provider>;
};
