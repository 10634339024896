import {UploadFile} from "../features/upload";
import {useHistory} from "react-router-dom";
import {Routes} from "../../utils";
import {useRegistrationContext} from "../../contexts/registration/registrationContext";

const RegistrationReferralView: React.VFC = () => {
    const history = useHistory();
    const { data, dispatch } = useRegistrationContext();
    
    const handleFinish = (id: string) => {
        dispatch({
            type: "SET_REFERRAL",
            payload: id
        });
        history.push(Routes.STUDY_DATE);
    }
    
    const handleCancel = () => {
        history.push(Routes.FORM_VIEW);
    }
    
    return (
        <UploadFile
            allowBackToPersonalData
            allowMakePhoneRequest
            firstName={data.personalData.firstName}
            lastName={data.personalData.lastName}
            onFinish={handleFinish}
            onCancel={handleCancel}
        />
    );
}

export default RegistrationReferralView;
