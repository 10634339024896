import styled from 'styled-components';
import { Button, ButtonProps } from "antd";
import { useThemeContext } from "contexts/theme";
import { constants } from "utils";

interface IProps extends ButtonProps, IAccessibility {
    bordered?: string;
}

const StyledButton = styled(Button)<IProps>`
    font-size: 1rem;
    line-height: 1rem;

    &, &:hover, &:active, &:focus {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        background-color: ${props => props.backcolor};
        border: ${props => props.bordered === "true" ? `1px solid ${constants.COLOR.TEXT}` : null};
        border-radius: 0px;
    }
`;

const AccessibilityButton: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledButton size="large" type="link" {...theme} {...props}>
            {props.children}
        </StyledButton>
    );
}

export default AccessibilityButton;
