import {AxiosError} from "axios";
import {TFunction} from "i18next";
import {CustomFuncModal} from "../components/common";
import {notification} from "antd";
import {Routes} from "./index";

const interceptApiError = (error: AxiosError | null, t: TFunction, history: any) => {
    if (error?.response?.status === 503) {
        notification.error({
            message: t("common:EXTERNAL_SERVICE_UNAVAILABLE"),
            description: t("common:EXTERNAL_SERVICE_UNAVAILABLE_INFO")
        });
    } else if (error?.response?.status === 401) {
        CustomFuncModal.displayError({
            title: t("common:SESSION_EXPIRED"),
            content: t("common:SESSION_EXPIRED_INFO"),
            okText: t("common:BACK_TO_PERSONAL_DATA"),
            onCancel: () => history?.push(Routes.FORM_VIEW),
            onOk: () => history?.push(Routes.FORM_VIEW)
        });
    } else {
        notification.error({
            message: t("common:UNKNOWN_ERROR_OCCURED"),
            description: t("common:UNKNOWN_ERROR_OCCURED_INFO")
        });
    }
}

export {
    interceptApiError
}
