import { useEffect, useState } from "react";
import { Col, Form, FormProps, notification, Row, Select, Radio, Space } from "antd";
import { Link, useHistory } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import {
    CustomCheckbox,
    CustomDescription,
    CustomFormItem,
    CustomFuncModal,
    CustomInput,
    CustomMaskedInput,
    CustomRadio,
    CustomSelect,
    CustomSwitch,
    CustomTable,
    Header,
    PrimaryButton,
    SecondaryButton
} from "components/common";
import { PlusOutlined } from "@ant-design/icons";
import AddLocationForm from "./AddLocationForm";
import { useTranslation } from "react-i18next";
import { useRegistrationContext } from "contexts/registration/registrationContext";
import { hasPhoneNumber, phoneFormatter, validateDO, validatePassport, validatePesel, validatePhoneNumber } from "utils/helpers";
import { useNonInitialEffect } from "hooks";
import { constants, Routes } from "utils";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as dal from "dal";
import { AxiosError } from "axios";
import UnderlineLink from "components/common/UnderlineLink";
import { customValidator } from "utils/validators";

interface IProps extends FormProps {}

type FundingResponse = {
    fundings: IFunding[];
    displayFundingsInRegistrationPortal: boolean;
};

const PersonalDataForm: React.VFC<IProps> = props => {
    const [form] = Form.useForm();
    const { push } = useHistory();
    const { dispatch, data } = useRegistrationContext();
    const { t } = useTranslation(["personalData", "common"]);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [pesel, setPesel] = useState("");
    const [passport, setPassport] = useState("");
    const [idCard, setIdCard] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [hasReferral, setHasReferral] = useState(false);

    const [idCardValidationStatus, setIdCardValidationStatus] = useState<ValidateStatus>("");
    const [validateStatusMessage, setValidateStatusMessage] = useState("");
    const [isAddingLocation, setIsAddingLocation] = useState(false);
    const [hasPesel, setHasPesel] = useState(true);
    const [isVerifyingRequest, setVeryfyingRequest] = useState(false);

    const [isLookingPrivateScreenings, setIsLookingPrivateScreenings] = useState(false);

    const [fundings, setFundings] = useState<FundingResponse>();

    const LABEL = t("personalData:FORM_LABEL", { returnObjects: true });
    const defaultCheckedHasPesel = Boolean(data.personalData?.pesel) || !(data.personalData?.passport || data.personalData?.idCard);
    const { INPUT_MAX_LENGTH } = constants.THRESHOLDS;

    useEffect(() => {
        setHasPesel(defaultCheckedHasPesel);
        setPassport(data.personalData?.passport);
        setIdCard(data.personalData?.idCard);
        setPhoneNumber(data.personalData?.phone);
        setFirstName(data.personalData?.firstName);
        setLastName(data.personalData?.lastName);
        setIsLookingPrivateScreenings(data.type.type === "paid");
    }, [data.personalData?.pesel]);

    useEffect(() => {
        dispatch({
            type: "SET_REFERRAL",
            payload: ""
        });

        const loadFundings = () => {
            dal.funding.getFundings().then((data: FundingResponse) => setFundings(data));
        };

        loadFundings();
    }, []);

    useNonInitialEffect(() => {
        if (!hasPesel) {
            setPesel("");
            setIdCardValidationStatus("");
            setValidateStatusMessage("");
            form.setFieldsValue({
                pesel: "",
                idCard: "",
                passport: "",
                type: "paid",
                terms: false
            });
        } else {
            form.setFieldsValue({
                pesel: "",
                idCard: "",
                passport: "",
                type: "nfz",
                terms: false
            });
        }
    }, [hasPesel]);

    const locationColumns: ColumnsType<any> | undefined = [
        {
            key: "province",
            title: LABEL.PROVINCE,
            dataIndex: "province"
        },
        {
            key: "district",
            title: LABEL.DISTRICT,
            dataIndex: "district"
        },
        {
            key: "community",
            title: LABEL.COMMUNE,
            dataIndex: "community"
        },
        {
            key: "city",
            title: LABEL.CITY,
            dataIndex: "city"
        },
        {
            title: "",
            render: (render: ILocation) => (
                <SecondaryButton
                    onClick={() =>
                        dispatch({
                            type: "SET_LOCATIONS",
                            payload: data.personalData.locations.filter(l => l.id !== render.id)
                        })
                    }
                    type="link"
                >
                    {t("personalData:REMOVE_FROM_LIST")}
                </SecondaryButton>
            )
        }
    ];

    const onLocationAdd = (location: ILocation) => {
        dispatch({
            type: "SET_LOCATIONS",
            payload: [...(data?.personalData.locations || []), location]
        });
        setIsAddingLocation(false);
    };

    const displayFailedCaptchaExecutionMessage = () => {
        CustomFuncModal.displayError({
            title: t("personalData:CAPTCHA_ERROR_TITLE"),
            content: t("personalData:CAPTCHA_ERROR_INFO"),
            okText: t("personalData:CAPTCHA_ERROR_CLOSE")
        });

        setVeryfyingRequest(false);
    };

    const displayFailedCaptchaVeryficationMessage = (error: AxiosError) => {
        if (error.response?.data === "CAPTCHA_VALIDATION_ERROR") {
            CustomFuncModal.displayError({
                title: t("personalData:CAPTCHA_ERROR_TITLE"),
                content: t("personalData:CAPTCHA_ERROR_INFO"),
                okText: t("personalData:CAPTCHA_ERROR_CLOSE")
            });
        } else if (error.response?.data === "SESSION_COULD_NOT_BE_ESTABLISHED") {
            CustomFuncModal.displayError({
                title: t("personalData:SESSION_ERROR_TITLE"),
                content: t("personalData:SESSION_ERROR_INFO"),
                okText: t("personalData:CAPTCHA_ERROR_CLOSE")
            });
        } else {
            notification.error({
                message: t("personalData:UNKNOWN_ERROR_TITLE"),
                description: t("personalData:SESSION_ERROR_INFO")
            });
        }
    };

    const verifyCaptcha = async (): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (!executeRecaptcha) {
                reject();
            }

            executeRecaptcha?.(constants.CAPTCHA_ACTIONS.PERSONAL_DATA_FORM).then(resolve).catch(reject);
        });
    };

    const onFinish = (values: any) => {
        if (data.personalData.locations.length <= 0) {
            return notification.warn({
                message: t("personalData:MIN_LOCATIONS_WARN")
            });
        }

        if (data.personalData.locations.length <= 0) {
            return notification.warn({
                message: t("personalData:MIN_INCLUDED_LOCATIONS")
            });
        }

        if (data.personalData.locations.length > 4) {
            return notification.warn({
                message: t("personalData:MAX_AMOUNT_OF_LOCATIONS")
            });
        }

        setVeryfyingRequest(true);

        verifyCaptcha()
            .then(token => {
                dal.security
                    .validateCaptchaToken(token)
                    .then(response => {
                        dispatch({
                            type: "SET_PERSONAL_DATA",
                            payload: {
                                ...values,
                                pesel: hasPesel ? values.pesel : "",
                                passport: (!hasPesel && values.passport) || "",
                                idCard: (!hasPesel && values.idCard) || ""
                            }
                        });
                        dispatch({
                            type: "SET_TYPE",
                            payload: {
                                type: values.type,
                                referral: values.referral
                            }
                        });
                        sessionStorage.setItem(constants.STORAGE_KEYS.SESSION_TOKEN, response.token);
                        push(values.referral ? Routes.REFERRAL : Routes.QUESTIONNAIRE);
                    })
                    .catch(displayFailedCaptchaVeryficationMessage)
                    .finally(() => setVeryfyingRequest(false));
            })
            .catch(displayFailedCaptchaExecutionMessage);
    };

    const isLocationAlreadyAdded = () => data.personalData?.locations && data.personalData?.locations?.length >= 1;

    const shouldDisplayLocationForm = () => isAddingLocation && !isLocationAlreadyAdded();

    const canAccessContactPage = () => {
        if (!hasPhoneNumber(phoneNumber)) return false;
        if (!firstName || firstName.length <= 0) return false;
        if (!lastName || lastName.length <= 0) return false;
        return true;
    };

    const isIdCardValid = (value: any) => {
        const isValid = validateDO(value);

        if (!isValid) {
            setIdCardValidationStatus("warning");
            setValidateStatusMessage(t("common:INVALID_IDENTITY_CARD_NUMBER"));
        } else {
            setValidateStatusMessage("");
            setIdCardValidationStatus("");
        }

        return Promise.resolve();
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
                ...data.personalData,
                type: data.type.type,
                referral: data.type.referral,
                terms: false
            }}
            {...props}
        >
            <Header>{t("personalData:CHOOSE_EXAMINATION_TYPE")}</Header>
            <CustomDescription>{t("personalData:CHOOSE_EXAMINATION_TYPE_SUB_TITLE")}</CustomDescription>
            <Row>
                <CustomFormItem name="type">
                    <Radio.Group
                        onChange={e => {
                            form.setFieldsValue({
                                type: e.target.value,
                                referral: false
                            });
                            setIsLookingPrivateScreenings(e.target.value === "paid");
                            setHasReferral(false);
                        }}
                    >
                        <Space direction="vertical">
                            <CustomRadio disabled={!hasPesel} value={"nfz"}>
                                {t("personalData:LOOKING_NFZ")}
                            </CustomRadio>
                            <CustomRadio value={"paid"}>{t("personalData:LOOKING_PAID_RESEARCH")}</CustomRadio>
                        </Space>
                    </Radio.Group>
                </CustomFormItem>
            </Row>
            {isLookingPrivateScreenings && (
                <CustomFormItem name="referral" label="" valuePropName="checked">
                    <CustomCheckbox
                        onChange={e => {
                            form.setFieldsValue({
                                referral: e.target.checked
                            });
                            setHasReferral(e.target.checked);
                        }}
                    >
                        {t("personalData:HAVE_REFERRAL")}
                    </CustomCheckbox>
                </CustomFormItem>
            )}
            <Row style={{ marginTop: 50 }}>
                <Header>{LABEL.INPUT_PLACEHOLDER.PERSONAL_DATA}</Header>
                <CustomDescription>{t("personalData:PERSONAL_DATA_SUB_TITLE")}</CustomDescription>
            </Row>
            <Row gutter={25} style={{ marginTop: 25 }}>
                <Col md={6} span={24}>
                    <CustomFormItem
                        name="firstName"
                        label={LABEL.FIRST_NAME}
                        rules={[
                            {
                                required: true,
                                message: t("common:REQUIRED_FORM_FIELD")
                            },
                            {
                                max: INPUT_MAX_LENGTH,
                                message: t("common:TOO_LONG_INPUT")
                            }
                        ]}
                    >
                        <CustomInput
                            onChange={e => {
                                form.setFieldsValue({ firstName: e.target.value });
                                setFirstName(e.target.value);
                            }}
                            placeholder={LABEL.INPUT_PLACEHOLDER.FIRST_NAME}
                        />
                    </CustomFormItem>
                </Col>
                <Col md={6} span={24}>
                    <CustomFormItem
                        name="lastName"
                        label={LABEL.LAST_NAME}
                        rules={[
                            {
                                required: true,
                                message: t("common:REQUIRED_FORM_FIELD")
                            },
                            {
                                max: INPUT_MAX_LENGTH,
                                message: t("common:TOO_LONG_INPUT")
                            }
                        ]}
                    >
                        <CustomInput
                            onChange={e => {
                                form.setFieldsValue({ lastName: e.target.value });
                                setLastName(e.target.value);
                            }}
                            placeholder={LABEL.INPUT_PLACEHOLDER.LAST_NAME}
                        />
                    </CustomFormItem>
                </Col>
                <Col md={6} span={24}>
                    <CustomFormItem
                        name="phone"
                        label={LABEL.PHONE}
                        normalize={value => phoneFormatter(value)}
                        rules={[
                            {
                                required: true,
                                message: t("common:REQUIRED_FORM_FIELD")
                            },
                            {
                                validator: (_, value) => customValidator(validatePhoneNumber, value, true, t("common:INVALID_PHONE_NUMBER"))
                            },
                            {
                                max: INPUT_MAX_LENGTH,
                                message: t("common:TOO_LONG_INPUT")
                            }
                        ]}
                    >
                        <CustomInput
                            onChange={e => {
                                setPhoneNumber(e.target.value);
                            }}
                            placeholder={LABEL.INPUT_PLACEHOLDER.PHONE_NUMBER}
                        />
                    </CustomFormItem>
                </Col>
                <Col md={6} span={24}>
                    <CustomFormItem
                        name="email"
                        label={LABEL.EMAIL}
                        rules={[
                            {
                                type: "email",
                                message: t("common:INVALID_EMAIL_ADDRESS")
                            },
                            {
                                max: INPUT_MAX_LENGTH,
                                message: t("common:TOO_LONG_INPUT")
                            }
                        ]}
                    >
                        <CustomInput placeholder={LABEL.INPUT_PLACEHOLDER.EMAIL} />
                    </CustomFormItem>
                </Col>
            </Row>
            <Row gutter={25}>
                <Col md={6} span={24}>
                    <CustomFormItem name="" label={t("personalData:HAVE_PESEL")}>
                        <CustomSwitch checked={hasPesel} onChange={e => setHasPesel(e)} />
                    </CustomFormItem>
                </Col>
                {hasPesel ? (
                    <Col md={6} span={24}>
                        <CustomFormItem
                            rules={[
                                {
                                    required: hasPesel,
                                    message: t("common:REQUIRED_FORM_FIELD")
                                },
                                {
                                    validator: (_, value) => customValidator(validatePesel, value, true, t("common:INVALID_PESEL"))
                                }
                            ]}
                            name="pesel"
                            label="PESEL"
                        >
                            <CustomMaskedInput
                                mask="11111111111"
                                onChange={e => {
                                    form.setFieldsValue({
                                        pesel: e.target.value
                                    });
                                    setPesel(e.target.value);
                                }}
                            />
                        </CustomFormItem>
                    </Col>
                ) : (
                    <>
                        <Col md={6} span={24}>
                            <CustomFormItem
                                validateStatus={idCardValidationStatus}
                                rules={[
                                    {
                                        required: !Boolean(passport),
                                        message: t("common:REQUIRED_FORM_FIELD")
                                    },
                                    {
                                        validator: (_, value) => (!Boolean(passport) ? isIdCardValid(value) : Promise.resolve())
                                    }
                                ]}
                                name="idCard"
                                help={validateStatusMessage}
                                label={LABEL.CARD_ID}
                                dependencies={["passport"]}
                            >
                                <CustomInput
                                    disabled={!!pesel}
                                    placeholder={LABEL.INPUT_PLACEHOLDER.CARD_ID}
                                    onChange={e => {
                                        form.setFieldsValue({
                                            idCard: e.target.value
                                        });
                                        setIdCard(e.target.value);
                                    }}
                                />
                            </CustomFormItem>
                        </Col>
                        <Col md={6} span={24}>
                            <CustomFormItem
                                rules={[
                                    {
                                        required: !Boolean(idCard),
                                        message: t("common:REQUIRED_FORM_FIELD")
                                    },
                                    {
                                        validator: (_, value) => customValidator(validatePassport, value, !Boolean(idCard), t("common:INVALID_PASSPORT"))
                                    },
                                    {
                                        max: INPUT_MAX_LENGTH,
                                        message: t("common:TOO_LONG_INPUT")
                                    }
                                ]}
                                name="passport"
                                label={LABEL.PASSPORT}
                                dependencies={["idCard"]}
                            >
                                <CustomInput
                                    disabled={!!pesel}
                                    onChange={e => {
                                        form.setFieldsValue({
                                            passport: e.target.value
                                        });
                                        setPassport(e.target.value);
                                    }}
                                    placeholder={LABEL.INPUT_PLACEHOLDER.PASSPORT}
                                />
                            </CustomFormItem>
                        </Col>
                    </>
                )}
            </Row>
            {fundings?.displayFundingsInRegistrationPortal && (
                <Row gutter={25}>
                    <Col md={6} span={24}>
                        <CustomFormItem
                            name="insurance"
                            label={LABEL.INSURANCE}
                            rules={[
                                {
                                    max: INPUT_MAX_LENGTH,
                                    message: t("common:TOO_LONG_INPUT")
                                }
                            ]}
                        >
                            <CustomSelect>
                                {fundings?.fundings?.map(f => (
                                    <Select.Option value={f.id}>{f.name}</Select.Option>
                                ))}
                            </CustomSelect>
                        </CustomFormItem>
                    </Col>
                    <Col md={6} span={24}>
                        <CustomFormItem
                            name="policy"
                            label={LABEL.POLICY_NUMBER}
                            rules={[
                                {
                                    max: INPUT_MAX_LENGTH,
                                    message: t("common:TOO_LONG_INPUT")
                                }
                            ]}
                        >
                            <CustomInput placeholder={LABEL.INPUT_PLACEHOLDER.POLICY_NUMBER} />
                        </CustomFormItem>
                    </Col>
                </Row>
            )}
            <CustomDescription>{t("common:REQUIRED_FIELD")}</CustomDescription>
            <Row style={{ marginTop: 50 }} align="middle">
                <Col md={24} span={24}>
                    <Header>{t("personalData:CHOOSE_EXAMINATION_LOCATION")}</Header>
                    <CustomDescription>{t("personalData:CHOOSE_EXAMINATION_LOCATION_SUB_TITLE")}</CustomDescription>
                </Col>
            </Row>
            <Row>
                {!isLocationAlreadyAdded() && (
                    <SecondaryButton onClick={() => setIsAddingLocation(prev => !prev)}>
                        <PlusOutlined /> {t("personalData:ADD_NEW_LOCATION")}
                    </SecondaryButton>
                )}
            </Row>
            <Row gutter={[32, 8]} style={{ marginTop: 25 }}>
                <h2 style={{ marginLeft: 20 }}>{t("personalData:STUDY_LOCATION")}</h2>
                <Col span={24}>
                    <CustomTable
                        columns={locationColumns}
                        dataSource={data.personalData.locations}
                        rowKey="id"
                        locale={{ emptyText: t("personalData:NO_DATA_TABLE") }}
                    />
                </Col>
            </Row>
            {shouldDisplayLocationForm() && <AddLocationForm onVisibilityChange={setIsAddingLocation} onFinish={onLocationAdd} />}
            <Header style={{ marginTop: 25 }}>{t("common:TERMS_AND_CONDITIONS")}</Header>
            <Row gutter={8} align="middle" style={{ marginTop: 25 }}>
                <Col>
                    <CustomFormItem
                        name="terms"
                        rules={[
                            {
                                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error(t("common:REQUIRED_FORM_FIELD"))))
                            }
                        ]}
                    >
                        <CustomCheckbox
                            onChange={e =>
                                form.setFieldsValue({
                                    terms: e.target.checked
                                })
                            }
                        >
                            {t("common:I_ACCEPT")}{" "}
                            <UnderlineLink href="regulamin.pdf" target="_blank">
                                {t("common:TERMS_AND_CONDITIONS")}
                            </UnderlineLink>{" "}
                            {t("common:OF_PORTAL_REGISTRATION")}
                        </CustomCheckbox>
                    </CustomFormItem>
                </Col>
            </Row>
            <Row style={{ marginTop: 50 }} gutter={[32, 8]}>
                <Col span={24}>
                    {canAccessContactPage() && (
                        <Link to={Routes.PHONE_CONTACT}>
                            <SecondaryButton style={{ marginRight: 25 }}>{t("common:REQUEST_PHONE_CALL")}</SecondaryButton>
                        </Link>
                    )}
                    <PrimaryButton htmlType="submit" loading={isVerifyingRequest}>
                        {t("common:GO_FURTHER")}
                    </PrimaryButton>
                </Col>
            </Row>
        </Form>
    );
};

export default PersonalDataForm;
