import React from "react";
import MaskedInput from "antd-mask-input";
import styled from "styled-components";
import { useThemeContext } from "contexts/theme";
import { constants } from "utils";
import {InputProps} from "antd";

interface IProps extends InputProps {
    mask: string;
    name?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const StyledMaskedInput = styled(MaskedInput as any)<IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;

    &,
    &:hover,
    &:active,
    &:focus {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
        border: 1px solid ${props => props.forecolor || "#d9d9d9"};
    }

    ::placeholder {
        color: ${props => props.forecolor};
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: ${props => props.forecolor};
    }

    ::-ms-input-placeholder {
        color: ${props => props.forecolor};
    }
`;

const CustomMaskedInput = (props: IProps) => {
    const { theme } = useThemeContext();

    return (
        <StyledMaskedInput {...theme} {...props} />
    );
};

export default CustomMaskedInput;
