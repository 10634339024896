import { Empty, Pagination, Table } from "antd";
import styled from "styled-components";
import { TableProps } from "antd/lib/table";
import { constants } from "utils";
import { useThemeContext } from "contexts/theme";

interface ICustomTableProps extends TableProps<any> {
    noBorderRound?: boolean;
    onlyTopBorder?: boolean;
    onChangePage?: (page: number) => void;
}

export default function CustomTable(props: ICustomTableProps) {
    const { theme } = useThemeContext();
    return (
        <TableContanier>
            <MobileTable {...theme}>
                {props.dataSource?.length === 0 && (
                    <Empty
                        style={{
                            marginBottom: 25
                        }}
                    />
                )}
                {props.dataSource &&
                    Array.isArray(props.dataSource) &&
                    props.dataSource.map((data: any, index: number) => (
                        <div className="table-row" key={index}>
                            {props.columns &&
                                Array.isArray(props.columns) &&
                                props.columns.map((column: any, index: number) => (
                                    <div className="table-column" key={index}>
                                        <b style={{ fontWeight: 600 }}>{column.title}</b>:
                                        <div className="table-data">{column.render ? column.render(data[column.key] || data, data) : data[column.key]}</div>
                                    </div>
                                ))}
                        </div>
                    ))}
                <Pagination
                    style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    showSizeChanger={false}
                    hideOnSinglePage={true}
                    onChange={page => props.onChangePage && props.onChangePage(page)}
                    {...props.pagination}
                />
            </MobileTable>
            <DeskptopTable {...theme}>
                <StyledTable
                    {...theme}
                    {...props}
                    pagination={{
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                        ...props.pagination
                    }}
                    onChange={(pagination: any) => props.onChangePage && props.onChangePage(pagination.current)}
                />
            </DeskptopTable>
        </TableContanier>
    );
}

const StyledTable = styled(Table)<ICustomTableProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;

    .ant-table-tbody > tr > td {
        background-color: ${props => props.backcolor};
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        border-color: ${props => props.forecolor};
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: ${props => props.backcolor};
    }

    .ant-table-thead > tr > th {
        background-color: ${props => props.backcolor};
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        border-color: ${props => props.forecolor};
    }
`;

const TableContanier = styled.div`
    .table-mobile {
        padding: 24px;
    }
`;

const MobileTable = styled.div<IAccessibility>`
    display: none;
    background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
    color: ${props => props.forecolor || constants.COLOR.TEXT};
    font-size: 1rem;
    line-height: 1rem;

    @media screen and (max-width: 1200px) {
        display: block;
    }
`;

const DeskptopTable = styled.div<IAccessibility>`
    display: block;
    background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
    color: ${props => props.forecolor || constants.COLOR.TEXT};

    .ant-table {
        font-size: 1rem;
        line-height: 1rem;
    }

    @media screen and (max-width: 1200px) {
        display: none;
    }
`;
