import { CustomDescription, PrimaryButton, SecondaryButton } from "components/common";
import { useTranslation } from "react-i18next";
import { useRegistrationContext } from "../../contexts/registration/registrationContext";
import CustomResult from "../common/CustomResult";
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import { constants, Routes } from "../../utils";
import { hasPhoneNumber } from "../../utils/helpers";
import { useEffect, useState } from "react";
import * as dal from "dal";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { interceptApiError } from "../../utils/interceptors";

export default function SuccessfulRegistrationView() {
    const { t } = useTranslation(["common", "successfulRegistration"]);
    const { data } = useRegistrationContext();
    const { funding } = data?.selectedRegistration || {};
    const { phone } = data?.personalData || {};
    const { executeRecaptcha } = useGoogleReCaptcha();
    const history = useHistory();

    const [isPaying, setPaying] = useState(false);

    const payForRegistration = () => {
        setPaying(true);

        const { personalData, selectedRegistration } = data || {};

        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.PAY_FOR_REGISTRATION)
            .then(token => {
                dal.screening
                    .payForCampaignRegistration(selectedRegistration?.campaignRegistration?.token || "", personalData, token)
                    .then(res => window.open(res.redirectUri, "_self"))
                    .catch(e => interceptApiError(e, t, history))
                    .finally(() => setPaying(false));
            })
            .catch(() => setPaying(false));
    };

    return (
        <>
            <CustomResult status="success" title={t("successfulRegistration:TITLE")} />
            <CustomDescription>{t("successfulRegistration:DESCRIPTION")}</CustomDescription>
            {funding?.isPaid && (
                <>
                    <CustomDescription>{t("successfulRegistration:SELECTED_PAID_DATE")}</CustomDescription>
                    <Row>
                        <Col span={24}>
                            {hasPhoneNumber(phone) && (
                                <Link to={Routes.PHONE_CONTACT} style={{ marginRight: 25 }}>
                                    <SecondaryButton>{t("common:REQUEST_PHONE_CALL")}</SecondaryButton>
                                </Link>
                            )}
                            <PrimaryButton style={{ marginTop: 50 }} loading={isPaying} onClick={payForRegistration}>
                                {t("successfulRegistration:PAID_NOW_ONLINE")} ({(funding?.price || 0).toFixed(2)} PLN)
                            </PrimaryButton>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}
