import { useState, useEffect } from "react";
import { Col, Row, Form, Select } from "antd";
import { CustomInput, CustomSelect, PrimaryButton, SecondaryButton, CustomFormItem, CustomMaskedInput } from "components/common";
import { useThemeContext } from "contexts/theme";
import * as dal from "dal";
import { useNonInitialEffect } from "hooks";
import { LabeledValue } from "antd/lib/select";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { constants } from "utils";
import { useHistory } from "react-router-dom";
import { interceptApiError } from "../../../utils/interceptors";
import SearchCityInput from "./SearchCityInput";

interface IProps {
    onFinish: (location: ILocation) => void;
    onVisibilityChange: (isVisible: boolean) => void;
}

const AddLocationForm = ({ onFinish, onVisibilityChange }: IProps) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { theme } = useThemeContext();
    const { t } = useTranslation(["personalData", "common"]);

    const { Option } = Select;

    const [provinces, setProvinces] = useState<IProvince[]>([]);
    const [districts, setDistricts] = useState<IDistrict[]>([]);
    const [communities, setCommunites] = useState<ICommunity[]>([]);
    const [city, setCity] = useState<string>("");

    const [isLoadingProvinces, setIsLoadingProvinces] = useState(false);
    const [isLoadingDistricts, setIsLoadingDistricts] = useState(false);
    const [isLoadingCommunities, setIsLoadingCommunities] = useState(false);

    const [selectedProvinceId, setSelectedProvinceId] = useState(0);
    const [selectedProvinceName, setSelectedProvinceName] = useState("");
    const [selectedDistrictId, setSelectedDistrictId] = useState(0);
    const [selectedDistrictName, setSelectedDistrictName] = useState("");
    const [selectedCommunityName, setSelectedCommunityName] = useState("");
    const [selectedCommunityId, setSelectedCommunityId] = useState(0);

    const { INPUT_MAX_LENGTH } = constants.THRESHOLDS;

    useEffect(() => {
        fetchProvinces();
    }, []);

    const fetchProvinces = () => {
        setIsLoadingProvinces(true);

        dal.territory
            .getProvinces()
            .then(res => setProvinces(res.data))
            .catch(e => interceptApiError(e, t, history))
            .finally(() => setIsLoadingProvinces(false));
    };

    const fetchDistricts = () => {
        setIsLoadingDistricts(true);

        dal.territory
            .getDistricts(selectedProvinceId)
            .then(res => setDistricts(res.data))
            .catch(e => interceptApiError(e, t, history))
            .finally(() => setIsLoadingDistricts(false));
    };

    const fetchCommunities = () => {
        setIsLoadingCommunities(true);

        dal.territory
            .getCommunities(selectedProvinceId, selectedDistrictId)
            .then(res => setCommunites(res.data))
            .catch(e => interceptApiError(e, t, history))
            .finally(() => setIsLoadingCommunities(false));
    };

    useNonInitialEffect(() => {
        if (selectedProvinceId !== 0) {
            setDistricts([]);
            setCommunites([]);
            fetchDistricts();
        }
    }, [selectedProvinceId]);

    useNonInitialEffect(() => {
        if (selectedDistrictId !== 0) {
            setCommunites([]);
            fetchCommunities();
        }
    }, [selectedDistrictId]);

    const onSubmit = async () => {
        form.submit();
        const isValid = await form.validateFields();

        if (isValid) {
            onFinish({
                ...isValid,
                districtId: selectedDistrictId,
                communityId: selectedCommunityId,
                provinceId: selectedProvinceId,
                id: uuid(),
                community: selectedCommunityName,
                district: selectedDistrictName,
                province: selectedProvinceName
            });
            form.resetFields();
        }
    };

    const LABEL = t("personalData:FORM_LABEL", { returnObjects: true });

    return (
        <Form
            name="location"
            form={form}
            initialValues={{
                districtId: undefined,
                communityId: undefined
            }}
            style={{
                padding: 25,
                border: `2px solid ${theme.forecolor || constants.COLOR.PRIMARY}`
            }}
        >
            <Row {...theme} gutter={[32, 8]}>
                <Col md={5} span={24}>
                    <CustomFormItem
                        label={LABEL.PROVINCE}
                        name="provinceId"
                        rules={[
                            {
                                required: true,
                                message: t("common:REQUIRED_FIELD")
                            }
                        ]}
                    >
                        <CustomSelect
                            showSearch
                            labelInValue
                            placeholder={LABEL.PROVINCE}
                            loading={isLoadingProvinces}
                            allowClear
                            onSelect={(e: LabeledValue) => {
                                setSelectedProvinceId(e.value as number);
                                setSelectedProvinceName(e.label as string);

                                form.setFieldsValue({
                                    provinceId: e
                                });
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {provinces.length !== 0 &&
                                provinces.map((m: IProvince) => (
                                    <Option key={m.id} value={m.tId}>
                                        {`${m.name}`}
                                    </Option>
                                ))}
                        </CustomSelect>
                    </CustomFormItem>
                </Col>
                <Col md={5} span={24}>
                    <CustomFormItem
                        label={LABEL.DISTRICT}
                        name="districtId"
                        rules={[
                            {
                                required: true,
                                message: t("common:REQUIRED_FIELD")
                            }
                        ]}
                    >
                        <CustomSelect
                            showSearch
                            placeholder={LABEL.DISTRICT}
                            disabled={districts.length === 0}
                            loading={isLoadingDistricts}
                            labelInValue
                            allowClear
                            onSelect={(e: LabeledValue) => {
                                setSelectedDistrictId(e.value as number);
                                setSelectedDistrictName(e.label as string);
                                form.setFieldsValue({
                                    districtId: e
                                });
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {provinces.length !== 0 &&
                                districts.map((m: IDistrict) => (
                                    <Select.Option key={m.id} value={m.pow}>
                                        {`${m.name}`}
                                    </Select.Option>
                                ))}
                        </CustomSelect>
                    </CustomFormItem>
                </Col>
                <Col md={5} span={24}>
                    <CustomFormItem
                        label={LABEL.COMMUNE}
                        name="communityId"
                        rules={[
                            {
                                required: true,
                                message: t("common:REQUIRED_FIELD")
                            }
                        ]}
                    >
                        <CustomSelect
                            showSearch
                            placeholder={LABEL.COMMUNE}
                            disabled={communities.length === 0}
                            labelInValue
                            loading={isLoadingCommunities}
                            allowClear
                            onSelect={(e: LabeledValue) => {
                                setSelectedCommunityName(e.label as string);
                                setSelectedCommunityId(e.value as number);
                                form.setFieldsValue({
                                    communityId: e
                                });
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {communities.map((m: ICommunity) => (
                                <Select.Option key={m.id} value={m.gmi}>
                                    {`${m.name}(${m.nazdod})`}
                                </Select.Option>
                            ))}
                        </CustomSelect>
                    </CustomFormItem>
                </Col>
                <Col md={5} span={24}>
                    <CustomFormItem
                        label={LABEL.CITY}
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: t("common:REQUIRED_FIELD")
                            },
                            {
                                max: INPUT_MAX_LENGTH,
                                message: t("common:TOO_LONG_INPUT")
                            }
                        ]}
                    >
                        <SearchCityInput
                            districtPow={selectedDistrictId}
                            communityGmi={selectedCommunityId}
                            provinceTid={selectedProvinceId}
                            city={city}
                            setCity={c => {
                                setCity(c);
                                form.setFieldsValue({
                                    city: c
                                });
                            }}
                        />
                    </CustomFormItem>
                </Col>
                <Col md={4} span={24}>
                    <CustomFormItem label={LABEL.POSTAL_CODE} name="postalCode">
                        <CustomMaskedInput
                            mask="11-111"
                            name={LABEL.POSTAL_CODE}
                            onChange={e =>
                                form.setFieldsValue({
                                    postalCode: e.target.value
                                })
                            }
                        />
                    </CustomFormItem>
                </Col>
            </Row>
            <Row style={{ marginTop: 25 }}>
                <Col span={24}>
                    <PrimaryButton onClick={onSubmit} style={{ marginRight: 25 }}>
                        {t("personalData:CONFIRM")}
                    </PrimaryButton>
                    <SecondaryButton onClick={() => onVisibilityChange(false)}>{t("common:CANCEL")}</SecondaryButton>
                </Col>
            </Row>
        </Form>
    );
};

export default AddLocationForm;
