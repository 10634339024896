import {UploadFile} from "../features/upload";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useEffect, useState} from "react";
import {CustomSpinner} from "../common";
import {constants, Routes} from "../../utils";
import * as dal from "dal";
import * as queryString from "querystring";
import {interceptApiError} from "../../utils/interceptors";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {ReferralStatusView} from "../features/studyView";
import {ReferralStatus} from "../../typings/enums";

const SendReferralView: React.VFC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { t } = useTranslation(["common"]);
    const history = useHistory();
    
    const [isLoading, setLoading] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [study, setStudy] = useState<IScreeningReferralResult | null>(null);

    const verifyCaptcha = async (): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (!executeRecaptcha) {
                reject();
            }

            executeRecaptcha?.(constants.CAPTCHA_ACTIONS.UPLOAD_REFERRAL).then(resolve).catch(reject);
        });
    };

    const handleSuccessfulStudyLoad = (study: any) => {
        setStudy(study);
        setLoading(false);
    }

    const getStudyInitialInformation = () => {
        const { registration } = queryString.parse(window.location.search.slice(1));

        verifyCaptcha()
            .then(token => {
                dal.security
                    .validateCaptchaToken(token)
                    .then(response => {
                        sessionStorage.setItem(constants.STORAGE_KEYS.SESSION_TOKEN, response.token);

                        dal.screening
                            .getScreeningInformation(registration?.toString())
                            .then(handleSuccessfulStudyLoad)
                            .catch(e => interceptApiError(e, t, history));
                    });
            })
            .catch();
    }
    
    useEffect(() => {
        getStudyInitialInformation();
    }, [executeRecaptcha]);
    
    if (isLoading || isUpdating) {
        return (
            <CustomSpinner size={90} style={{ marginTop: 100 }} />
        );
    }
    
    if (study?.status !== ReferralStatus.Ok) {
        return (
            <ReferralStatusView status={study?.status || ReferralStatus.UnknownError}/>
        )
    }
    
    const handleSuccessfulUpdateRegistrationReferal = (response: IScreeningReferralResult) => {
        if (response.status === ReferralStatus.Ok) {
            history.push(Routes.SUCCESSFUL_REFERRAL_UPLOAD);
        } else {
            interceptApiError(null, t, history);
        }
    }
    
    const updateRegistrationReferral = (id: string) => {
        setUpdating(true);
        
        executeRecaptcha?.()
            .then(token => {
                const { registration } = queryString.parse(window.location.search.slice(1));
                
                dal.screening
                    .updateRegistrationReferral(registration.toString(), id, token)
                    .then(handleSuccessfulUpdateRegistrationReferal)
                    .catch(e => interceptApiError(e, t, history))
                    .finally(() => setUpdating(false));
            })
            .catch(() => setUpdating(false));
    }
    
    return (
        <UploadFile
            firstName={study?.study.firstName || ""}
            lastName={study?.study.lastName || ""}
            studyDate={study?.study.studyDate}
            onFinish={updateRegistrationReferral}
        />
    );
}

export default SendReferralView;
