import { Row, Col, Space, notification } from "antd";
import { PrimaryButton, SecondaryButton, Header, CustomDescription } from "components/common";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useRegistrationContext } from "contexts/registration/registrationContext";
import { useTranslation } from "react-i18next";
import * as dal from "dal";
import { displayWarningNotification } from "utils/notifications";
import {interceptApiError} from "../../utils/interceptors";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {constants} from "../../utils";
import {useState} from "react";

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

const PhoneContactView = () => {
    const history = useHistory();
    
    const { data: { personalData } } = useRegistrationContext();
    const { t } = useTranslation(["common", "phoneContact"]);
    const { executeRecaptcha } = useGoogleReCaptcha();
    
    const [isSendingRequest, setSendingRequest] = useState(false);

    const onSubmit = () => {
        const { firstName, lastName, phone } = personalData || {};
        const { SUCCESS, ERROR, INTERNAL_SERVER_ERROR } = t("phoneContact:SERVER_RESPONSE", { returnObjects: true }) || {};

        if (!firstName || !lastName || !phone) return displayWarningNotification({ message: ERROR });

        setSendingRequest(true);
        
        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.CONFIRM_DATE)
            .then(token => {
                dal.phoneContact
                    .createPhoneContactRequest(
                    {
                        firstName,
                        lastName,
                        phoneNumber: phone
                    }, token)
                    .then(() => notification.success({ message: SUCCESS }))
                    .then(() => history.goBack())
                    .catch(error => interceptApiError(error, t, history))
                    .catch(() => displayWarningNotification({ message: INTERNAL_SERVER_ERROR }))
                    .finally(() => setSendingRequest(false))
            })
            .catch(() => setSendingRequest(false))
    };
    
    return (
        <Container>
            <Row style={{ width: "100%" }} gutter={[16, 16]}>
                <Col span={24}>
                    <Header>{t("phoneContact:TITLE")}</Header>
                </Col>
                <Col span={24}>
                    <CustomDescription>{t("phoneContact:SUBTITLE")}</CustomDescription>
                    <CustomDescription>{`${personalData?.firstName} ${personalData?.lastName} ${personalData?.phone}`} </CustomDescription>
                </Col>
                <Col span={24} style={{ marginTop: 25 }}>
                    <Space>
                        <SecondaryButton
                            style={{ marginRight: 25 }}
                            onClick={history.goBack}
                        >
                            {t("common:CANCEL")}
                        </SecondaryButton>
                        <PrimaryButton loading={isSendingRequest} onClick={onSubmit}> {t("phoneContact:CONFIRM")} </PrimaryButton>
                    </Space>
                </Col>
            </Row>
        </Container>
    );
};

export default PhoneContactView;
