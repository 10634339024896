import { useRegistrationContext } from 'contexts/registration/registrationContext';
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils';
import { v4 as uuid } from "uuid";

const useSuccessInvitation = () => {
  const { dispatch } = useRegistrationContext();

  const { push } = useHistory();

  return useCallback(({ patient, territory }: IInvitationResponse) => {
    const { email, pesel, firstName, lastName, mobileNumber, phoneNumber } = patient;

    const { city, community, district, postalCode, province } = territory;

    dispatch({ type: 'SET_PERSONAL_DATA', payload: {
      email,
      firstName,
      lastName,
      phone: mobileNumber || phoneNumber || '',
      pesel,
      locations: [{
        id: uuid(),
        city,
        postalCode,
        community: community.name,
        communityId: community.optionalId,
        district: district.name,
        districtId: district.optionalId,
        province: province.name,
        provinceId: province.optionalId,
      }],
      // default options, we are not fetching it
      idCard: '',
      insurance: '',
      nfz: true,
      paidResearch: false,
      passport: '',
      policy: '',
      referral: false,
    }});

    push(Routes.FORM_VIEW);
  }, [dispatch, push]);
}

export default useSuccessInvitation