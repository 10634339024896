import { Col, Form } from 'antd'
import { CustomDescription, CustomFormItem, CustomInput, CustomMaskedInput, Header, PrimaryButton, SecondaryButton } from 'components/common'
import InvitationForm from 'components/features/invitation/InvitationForm'
import { useInvitation } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Routes } from 'utils'
import { validateBirthDate } from 'utils/helpers'
import { getInvitationLink } from 'utils/invitation'
import { customValidator } from 'utils/validators'

const invitation = getInvitationLink();

const InvitationView = () => {
  const { t } = useTranslation();

  const { loading, verify } = useInvitation();

  return (
    <>
      <Header>
        {t("invitation:TITLE")}
      </Header>

      <CustomDescription>
        {t("invitation:DESCRIPTION")}
      </CustomDescription>

      <InvitationForm initialValues={{ accessCode: invitation }} onFinish={verify} buttons={<>
        <Link to={Routes.FORM_VIEW} style={{ marginRight: 25 }}>
          <SecondaryButton style={{ marginTop: 25 }}>{t("invitation:PERSONAL_DATA_LINK")}</SecondaryButton>
        </Link>

        <PrimaryButton loading={loading} htmlType="submit">
          {t("invitation:SUBMIT")}
        </PrimaryButton>
      </>} />
    </>
  );
}

export default InvitationView