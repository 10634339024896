import styled from "styled-components";
import AccessibilityButtons from "./AccessibilityButtons";
import LanguageSelector from "./LanguageSelector";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .container {
        display: flex;
        flex-direction: row;
    }

    @media screen and (max-width: 992px) {
        &, .container {
            flex-direction: column;
            align-items: center;
        }
    }
`;

const AccessibilityContainer: React.VFC = () => {
    return (
        <Container>
            <LanguageSelector style={{ marginRight: 15 }} />
            <AccessibilityButtons className="container" />
        </Container>
    )
}

export default AccessibilityContainer
