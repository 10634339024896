import React, { useContext, useReducer } from 'react';
import { themeReducer } from './themeReducer';

const accessibility: IAccessibility = {
    forecolor: "",
    backcolor: "",
    sizemode: ""
};

type ThemeStateProviderProps = {
    children: React.ReactChild | React.ReactChild[];
};

const ThemeContext = React.createContext<IThemeStore>({
    theme: accessibility,
    dispatch: () => {}
});

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider: React.FC<ThemeStateProviderProps> = ({ children }: ThemeStateProviderProps) => {
    const [theme, dispatch] = useReducer(themeReducer, accessibility);

    return (
        <ThemeContext.Provider value={{ theme, dispatch }}>
            {children}
        </ThemeContext.Provider>
    );
};
