import {ReferralStatus} from "../../../typings/enums";
import CustomResult from "../../common/CustomResult";
import {CustomDescription} from "../../common";
import {useTranslation} from "react-i18next";

interface IProps {
    status: ReferralStatus;
}

const ReferralStatusView: React.VFC<IProps> = ({ status }) => {
    const { t } = useTranslation(["referral"]);

    if (status === ReferralStatus.StudyCompleted) {
        return (
            <>
                <CustomResult
                    status="error"
                    title={t("referral:STUDY_COMPLETED_TITLE")}
                />
                <CustomDescription>
                    {t("referral:STUDY_COMPLETED_INFO")}
                </CustomDescription>
            </>
        );
    }

    return (
        <>
            <CustomResult
                status="error"
                title={t("referral:REFERRAL_UPLOAD_TITLE")}
            />
            <CustomDescription>
                {t("referral:REFERRAL_UPLOAD_INFO")}
            </CustomDescription>
        </>
    );
}

export default ReferralStatusView;
