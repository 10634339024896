const constants = {
    LANGUAGES: {
        PL: "pl",
        EN: "en"
    },
    STORAGE_KEYS: {
        LANGUAGE: "language",
        SIZE: "accessibility-size",
        CONTRAST: {
            FORECOLOR: "contrast-forecolor",
            BACKCOLOR: "contrast-backcolor"
        },
        SESSION_TOKEN: "token"
    },
    COLOR: {
        BACKGROUND: "#FFF",
        TEXT: "#000",
        PRIMARY: "#D24C76",
        PRIMARY_DARKEN: "#B02A54",
    },
    BREAKPOINTS: {
        MOBILE: 992
    },
    DATE_FORMATS: {
        DATE: "DD.MM.YYYY",
        DATE_TIME: "DD.MM.YYYY HH:mm",
        DAY_NAME: "dddd"
    },
    TIMEOUTS: {
        MAXIMUM_TIME_FOR_SELECT_STUDY_DATE: 180,
        MAXIMUM_TIME_TO_CONFIRM_STUDY_DATE: 120
    },
    CAPTCHA_ACTIONS: {
        PERSONAL_DATA_FORM: "PERSONAL_DATA_FORM",
        FIND_DATES: "FIND_DATES",
        CONFIRM_DATE: "CONFIRM_DATE",
        SEND_CONFIRMATION_CODE: "SEND_CONFIRMATION_CODE",
        BLOCK_SELECTED_DATE: "BLOCK_SELECTED_DATE",
        UPLOAD_REFERRAL: "UPLOAD_REFERRAL",
        PAY_FOR_REGISTRATION: "PAY_FOR_REGISTRATION",
        VERIFY_INVITATION: "VERIFY_INVITATION"
    },
    THRESHOLDS: {
        MAXIMUM_UPLOAD_FILE_SIZE: 5242880, // 5 MB
        INPUT_MAX_LENGTH: 255
    }
}

export default constants;
