import { Result, ResultProps } from "antd";
import { useThemeContext } from "contexts/theme";
import styled from "styled-components";

interface IProps extends ResultProps {
    children?: React.ReactNode | React.ReactNode[]
}

const StyledResult = styled(Result) <IProps & IAccessibility>`
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin: 50px 0px;
    align-items: center;
                    
    .ant-result-icon {
        margin: 0;
    }
    
    &.ant-result-success  .ant-result-icon > .anticon,
    &.ant-result-error .ant-result-icon > .anticon {
        color: ${props => props.forecolor};
        font-size: 35px;
    }
    
    .ant-result-title {
        font-size: 1.4rem;
        line-height: 1.4rem;
        margin-left: 20px;
        color: ${props => props.forecolor};
    }
`;

const CustomResult = (props: IProps) => {
    const { theme } = useThemeContext();

    return (
        <StyledResult {...theme} {...props}>
            {props.children}
        </StyledResult>
    );
};

export default CustomResult;
