import axios, { AxiosError, AxiosResponse } from "axios";
import {constants} from "./utils";
import {applicationNonce, applicationTimestamp} from "./utils/security";

export const api = axios.create({
    baseURL: `/api/iris-registration`
});

api.interceptors.request.use(config => {
    config.headers['X-Authorization'] = `Bearer ${sessionStorage.getItem(constants.STORAGE_KEYS.SESSION_TOKEN) || "none"}`;
    config.headers['X-Timestamp'] = applicationTimestamp;
    config.headers['X-Nonce'] = applicationNonce;

    return config;
});

api.interceptors.response.use(
    (response: AxiosResponse) => Promise.resolve(response),
    (error: AxiosError) => Promise.reject(error)
);
