import constants from "./constants";
import dayjs from "dayjs";
import parsePhoneNumber from "libphonenumber-js";
import isValidNumber from "libphonenumber-js/max";

const validatePesel = (pesel: string): boolean => {
    const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];

    if (pesel.length !== 11) return false;

    const controlSum = calculateControlSum(pesel, weights);
    const controlNum = controlSum % 10;
    const controlDigit = 10 - controlNum === 10 ? 0 : 10 - controlNum;
    const lastDigit = parseInt(pesel[pesel.length - 1].toString());

    return controlDigit == lastDigit;
};

const calculateControlSum = (input: string, weights: number[], offset = 0) => {
    let controlSum = 0;
    for (let i = 0; i < input.length - 1; i++) {
        controlSum += weights[i + offset] * parseInt(input[i].toString());
    }
    return controlSum;
};

const validatePhoneNumber = (phone: string) => {
    if (!phone) return false;
    const isPhoneValid = phone.startsWith("+") ? isValidNumber(phone) : isValidNumber(phone, "PL");
    return isPhoneValid?.isValid() || false;
};

const phoneFormatter = (phone: string | undefined): string => {
    if ((phone || "").startsWith("+")) {
        const phoneNumber = parsePhoneNumber(phone || "");
        if (phoneNumber) return phoneNumber.formatInternational();
    }

    const phoneNumber = parsePhoneNumber(phone || "", "PL");
    if (phoneNumber) return phoneNumber.formatNational();

    return phone ?? "";
};

const validatePassport = (passport: string) => {
    const regex = /^(?!^0+$)[a-zA-Z0-9]{3,20}$/;

    if (regex.test(passport) === false) return false;

    return true;
};

const getLetterValue = (letter: string) => {
    const letterValues = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
    ];

    for (let j = 0; j < letterValues.length; j++) {
        if (letter === letterValues[j]) return j;
    }

    return -1;
};

const validateDO = (number: string) => {
    if (number == null || number.length !== 9) return false;

    number = number.toUpperCase();

    for (let i = 0; i < 3; i++) {
        if (getLetterValue(number[i]) < 10) return false;
    }

    for (let i = 3; i < 9; i++) {
        if (getLetterValue(number[i]) < 0 || getLetterValue(number[i]) > 9) return false;
    }

    let sum =
        7 * getLetterValue(number[0]) +
        3 * getLetterValue(number[1]) +
        1 * getLetterValue(number[2]) +
        7 * getLetterValue(number[4]) +
        3 * getLetterValue(number[5]) +
        1 * getLetterValue(number[6]) +
        7 * getLetterValue(number[7]) +
        3 * getLetterValue(number[8]);

    sum %= 10;

    if (sum !== getLetterValue(number[3])) return false;

    return true;
};

const validateDOwithSeparator = (number: string) => {
    if (number == null || number.length !== 10) return false;

    if (number[3] !== " " && number[3] !== "-") return false;

    number = number.replace(/[\s-]/g, "");

    return validateDO(number);
};

const validateBirthDate = (birthAt: string) => {
    if (!/^\d{2}\.\d{2}\.\d{4}$/.test(birthAt)) {
        return false;
    }

    const [day, month, year] = birthAt.split('.').map(value => parseInt(value, 10));

    if (day < 0 || day > 31) {
        return false;
    }

    if (month < 0 || month > 12) {
        return false;
    }

    if (year < 1800 || year > dayjs().year()) {
        return false;
    }

    return true;
}

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const getDate = (date: string, format = constants.DATE_FORMATS.DATE) => dayjs(date).format(format);

const getDateTime = (date: string, format = constants.DATE_FORMATS.DATE_TIME) => dayjs(date).format(format);

const getDayName = (date: string, format = constants.DATE_FORMATS.DAY_NAME) => dayjs(date).format(format);

const hasPhoneNumber = (phone: string) => phone && phone.length > 0;

const generateRandomString = (length: number) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    var result = "";

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

export {
    validatePesel,
    validatePhoneNumber,
    phoneFormatter,
    validatePassport,
    validateDOwithSeparator,
    validateDO,
    validateBirthDate,
    capitalizeFirstLetter,
    getDate,
    getDateTime,
    getDayName,
    hasPhoneNumber,
    generateRandomString
};
