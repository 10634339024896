import { useEffect } from "react";
import { constants, storage } from "utils";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "contexts/theme";
import { MemoryRouter as Router, Route, Switch } from "react-router-dom";
import { MainLayout } from "components/layouts";
import {
    ConfirmStudyDateView,
    FormView,
    HomepageView,
    QuestionarieView,
    QueueView,
    StudyDateView,
    PhoneContactView,
    SuccessfulRegistrationView,
    SuccessfulPaymentView,
    FailedPaymentView, RegistrationReferralView, SendReferralView, SuccessfulReferralUploadView
} from "components/views";
import { QueuedPath } from "components/features/queue";
import { Routes } from "./utils";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import dayjs from "dayjs";
import InvitationView from "components/views/InvitationView";

require("dayjs/locale/pl");
require("dayjs/locale/en");

function App() {
    const { i18n } = useTranslation();
    const { dispatch } = useThemeContext();

    useEffect(() => {
        const language = storage.getItem(constants.STORAGE_KEYS.LANGUAGE) || constants.LANGUAGES.PL;

        i18n.changeLanguage(language);
        dayjs.locale(language);
        dispatch({
            type: "INITIAL_STATE_CHANGE",
            payload: {
                forecolor: storage.getItem(constants.STORAGE_KEYS.CONTRAST.FORECOLOR) as AccessibilityMode,
                backcolor: storage.getItem(constants.STORAGE_KEYS.CONTRAST.BACKCOLOR) as AccessibilityMode,
                sizemode: storage.getItem(constants.STORAGE_KEYS.SIZE) as AccessibilitySize
            }
        });
    }, [i18n, dispatch]);
    
    if (window.location.search.length > 0 && window.location.search.indexOf("registration") >= 0) {
        return (
            <Router>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language={i18n.language}>
                    <MainLayout>
                        <Switch>
                            <Route exact path={Routes.HOME_PAGE} component={SendReferralView} />
                            <Route path={Routes.SUCCESSFUL_REFERRAL_UPLOAD} component={SuccessfulReferralUploadView} />
                        </Switch>
                    </MainLayout>
                </GoogleReCaptchaProvider>
            </Router>
        )
    }
    
    return (
        <Router>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language={i18n.language}>
                <Route path="/">
                    <MainLayout>
                        <Switch>
                            <Route exact path={Routes.HOME_PAGE} component={HomepageView} />
                            <Route path={Routes.FORM_VIEW} component={FormView} />
                            <Route path={Routes.QUESTIONNAIRE} component={QuestionarieView} />
                            <QueuedPath path={Routes.STUDY_DATE} component={StudyDateView} />
                            <Route path={Routes.CONFIRM_STUDY_DATE} component={ConfirmStudyDateView} />
                            <Route path={Routes.REFERRAL} component={RegistrationReferralView} />
                            <Route path={Routes.PHONE_CONTACT} component={PhoneContactView} />
                            <Route path={Routes.QUEUE} component={QueueView} />
                            <Route path={Routes.SUCCESSFUL_PATIENT_REGISTRATION} component={SuccessfulRegistrationView} />
                            <Route path={Routes.SUCCESSFUL_PATIENT_PAYMENT} component={SuccessfulPaymentView} />
                            <Route path={Routes.FAILED_PATIENT_PAYMENT} component={FailedPaymentView} />
                            <Route path={Routes.INVITATION} component={InvitationView} />
                        </Switch>
                    </MainLayout>
                </Route>
            </GoogleReCaptchaProvider>
        </Router>
    );
}

export default App;
