import styled from "styled-components";
import {useThemeContext} from "../../../contexts/theme";
import {BasicProps} from "antd/es/layout/layout";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {getDate} from "../../../utils/helpers";
import {useTranslation} from "react-i18next";

interface IProps extends BasicProps {
    patient: IPatient | null;
}

const StyledContainer = styled.div<IAccessibility>`
    color: ${props => props.forecolor || "#f44242"};
    line-height: 1.25rem;
    font-size: 1.25rem;
    border-radius: 5px;
`;

const PatientInformation: React.VFC<IProps> = (props) => {
    const { theme } = useThemeContext();
    const { t } = useTranslation(["studyDate"]);
    
    const { patient } = props;
    
    const getClosetsReinbursedDate = (date: string) => {
        const daysAmount = dayjs(date).diff(dayjs(), "days");
        return `${t("studyDate:CLOSETS_REIMBURSED_DAY")} ${daysAmount} ${t("studyDate:CLOSETS_DAY")} (${getDate(date, "DD MMMM YYYY")})`
    }
    
    return (
        <>
            {patient?.ewusStatus !== 1 && ( // Inne niż pacjentka ubezpieczona
                <StyledContainer {...theme} {...props}>
                    <ExclamationCircleOutlined style={{ marginRight: 5 }}/> {t("studyDate:EWUS_FAILED_VERIFICATION")}
                </StyledContainer>
            )}
            {patient?.simpStatus !== 0 && ( // Inne niż pacjentka objęta programem (1 - pacjentka miała badanie w ciągu 2 lat, 5 pacjentka poza programem)
                <StyledContainer {...theme} {...props}>
                    <ExclamationCircleOutlined style={{ marginRight: 5 }}/> {t("studyDate:SIMP_FAILED_VERIFICATION")}{' '}
                    {patient?.reimbursedDatesStartsFrom && getClosetsReinbursedDate(patient.reimbursedDatesStartsFrom)}
                    {patient?.simpStatus === 1 && t("studyDate:PATIENT_STUDIED_IN_TWO_YEARS")}
                    {patient?.simpStatus === 5 && t("studyDate:PATIENT_OUT_OF_PROGRAM")}
                </StyledContainer>
            )}
        </>
    );
}

export default PatientInformation;
