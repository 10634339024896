import { ModalFuncProps } from "antd/lib/modal";
import { Modal } from "antd";
import { constants } from "../../utils";

export function displayConfirm(props: ModalFuncProps) {
    return Modal.confirm({
        className: "custom-func-modal",
        okButtonProps: {
            style: {
                backgroundColor: constants.COLOR.PRIMARY,
                border: "none"
            }
        },
        cancelButtonProps: {
            type: "link",
            style: {
                color: constants.COLOR.PRIMARY
            }
        },
        width: 600,
        ...props
    });
}

export function displayWarning(props: ModalFuncProps) {
    Modal.warning({
        className: "custom-func-modal",
        okButtonProps: {
            style: {
                backgroundColor: constants.COLOR.PRIMARY,
                border: "none"
            }
        },
        width: 600,
        ...props
    });
}

export function displaySuccess(props: ModalFuncProps) {
    Modal.success({
        className: "custom-func-modal",
        okButtonProps: {
            style: {
                backgroundColor: constants.COLOR.PRIMARY,
                border: "none"
            }
        },
        width: 600,
        ...props
    });
}

export function displayError(props: ModalFuncProps) {
    Modal.error({
        className: "custom-func-modal",
        okButtonProps: {
            style: {
                backgroundColor: constants.COLOR.PRIMARY,
                border: "none"
            }
        },
        width: 600,
        ...props
    });
}
