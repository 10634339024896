import React from "react";
import styled from "styled-components";
import { useThemeContext } from "contexts/theme";
import { constants } from "utils";

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const StyledLink = styled.a<IProps & IAccessibility>`
    color: ${props => props.forecolor || constants.COLOR.PRIMARY} !important;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
`;

const UnderlineLink: React.FC<IProps> = props => {
    const { theme } = useThemeContext();

    return (
        <StyledLink {...theme} {...props}>
            {props.children}
        </StyledLink>
    );
};

export default UnderlineLink;
