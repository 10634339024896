import { Input, InputProps } from 'antd';
import { useThemeContext } from 'contexts/theme';
import React from 'react';
import styled from 'styled-components';
import { constants } from 'utils';

interface IProps extends InputProps {}

const StyledInput = styled(Input)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;

    &, &:hover, &:active, &:focus {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
        border: 1px solid ${props => props.forecolor || "#d9d9d9"};
    }

    ::placeholder {
        color: ${props => props.forecolor};
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: ${props => props.forecolor};
    }

    ::-ms-input-placeholder {
        color: ${props => props.forecolor};
    }
`;

const CustomInput: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledInput type="link" {...theme} {...props}>
            {props.children}
        </StyledInput>
    );
}

export default CustomInput;
