import { Card, CardProps } from 'antd';
import { useThemeContext } from 'contexts/theme';
import React from 'react';
import styled from 'styled-components';
import { constants } from 'utils';

interface IProps extends CardProps {}

const StyledCard = styled(Card)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;
    color: ${props => props.forecolor || constants.COLOR.TEXT};
    background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
    border-color: ${props => props.forecolor};
    
    .ant-card-head {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
        border-color: ${props => props.forecolor};
        font-size: 1rem;
        line-height: 1rem;
    }

    .ant-card-extra {
        font-size: 1rem;
        line-height: 1rem;
    }
`;

const CustomCard: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledCard {...theme} {...props}>
            {props.children}
        </StyledCard>
    );
}

export default CustomCard;
