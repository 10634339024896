import { BasicProps } from "antd/lib/layout/layout";
import { useThemeContext } from "contexts/theme";
import styled from "styled-components"
import { constants } from "utils";

interface IProps extends BasicProps {
    time: number;
    allowColorizeTime?: boolean;
}


const StyledContainer = styled.div<IProps & IAccessibility>`
    color: ${props => props.time < 60 && props.allowColorizeTime ? "red" : props.forecolor || constants.COLOR.TEXT};
`;

const StudyDateCounter: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledContainer {...theme} {...props}>
            {props.children} <b>{props.time}s</b>
        </StyledContainer>
    )
}

export default StudyDateCounter
