import { api } from "api";

export const getProvinces = () => api.get("/public/provinces");

export const getDistricts = (provinceTID?: number) => api.get(`/public/districts`, { params: { provinceTID } });

export const getCommunities = (provinceTID?: number, districtPOW?: number) => api.get(`/public/communities`, { params: { provinceTID, districtPOW } });

type GetCityByTerritoryRequest = {
    provinceTid: number;
    districtPow: number;
    communityGmi: number;
    name: string;
};

export const findCityByTerritory = (request: GetCityByTerritoryRequest) => api.get("/public/find-city-per-territory", { params: { ...request } });
