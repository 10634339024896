import { api } from "api";

export const findScreeningsDates = (locations: ILocation[], pesel: string, paidResearch: boolean, nfz: boolean, token: string) =>
    api.post("/public/screenings", { locations, pesel, paidResearch, nfz, token });

export const sendConfirmationCode = (registrationId: number, phoneNumber: string, token: string) =>
    new Promise((resolve, reject) => {
        api.post(`/reservation/${registrationId}`, { phoneNumber, token })
            .then(res => res.data)
            .then(resolve)
            .catch(reject);
    });

export const confirmRegistration = (
    registrationId: number,
    code: string,
    patient: IPersonalData,
    referralId: string,
    isPaid: boolean,
    questionnaire: IQuestionnaire,
    token: string
) =>
    new Promise((resolve, reject) => {
        api.post(`/reservation/${registrationId}/confirm`, {
            code,
            token,
            patient: { ...patient, otherId: patient.idCard || patient.passport },
            referralId,
            questionnaire,
            isPaid
        })
            .then(res => res.data)
            .then(resolve)
            .catch(reject);
    });

export const confirmPayment = (paymentId: string) =>
    new Promise((resolve, reject) => {
        api.post(`/payments/${paymentId}/confirm`)
            .then(res => res.data)
            .then(resolve)
            .catch(reject);
    });

export const blockSindgleCampaignRegistration = (registrationToken: string, token: string) =>
    new Promise((resolve, reject) => {
        api.post(`/reservation/${registrationToken}/block`, { token })
            .then(res => res.data)
            .then(resolve)
            .catch(reject);
    });

export const payForCampaignRegistration = (id: string, personalData: IPersonalData, token: string) =>
    new Promise<IPaymentCreateResponse>((resolve, reject) => {
        api.post<IPaymentCreateResponse>(`/reservation/${id}/pay`, { ...personalData, token })
            .then(res => res.data)
            .then(resolve)
            .catch(reject);
    });

export const getScreeningInformation = (registrationToken: string) =>
    new Promise<IScreeningReferralResult>((resolve, reject) => {
        api.get<IScreeningReferralResult>(`/screenings/${registrationToken}/info`)
            .then(res => res.data)
            .then(resolve)
            .catch(reject);
    });

export const updateRegistrationReferral = (registrationToken: string, referralId: string, token: string) =>
    new Promise<IScreeningReferralResult>((resolve, reject) => {
        api.patch<IScreeningReferralResult>(`/screenings/${registrationToken}/referral`, { referralId, token })
            .then(res => res.data)
            .then(resolve)
            .catch(reject);
    });
