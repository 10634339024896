import { CustomDescription } from "components/common";
import { useTranslation } from "react-i18next";
import CustomResult from "../common/CustomResult";
import {useEffect} from "react";
import * as dal from "dal";
import queryString from "querystring";

export default function SuccessfulPaymentView() {
    const { t } = useTranslation(["common", "successfulPayment"]);

    useEffect(() => {
        const { paymentId } = queryString.parse(window.location.search.slice(1));

        const updatePaymentInformation = () => {
            dal.screening
                .confirmPayment(paymentId.toString() || "")
                .catch();
        }
        
        updatePaymentInformation();
    }, []);
    
    return (
        <>
            <CustomResult
                status="success"
                title={t("successfulPayment:TITLE")}
            />
            <CustomDescription>
                {t("successfulPayment:DESCRIPTION")}
            </CustomDescription>
        </>
    );
}
