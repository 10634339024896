import { Select, SelectProps } from 'antd';
import { useThemeContext } from 'contexts/theme';
import React from 'react';
import styled from 'styled-components';
import { constants } from 'utils';

interface IProps extends SelectProps<any> {}

const StyledSelect = styled(Select)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;

    &, &:hover, &:active, &:focus {
        color: ${props => props.forecolor || constants.COLOR.TEXT} !important;
        border-color: ${props => props.forecolor};
    }
    
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
        border-color: ${props => props.forecolor || "#d9d9d9"} !important;
    }

    .ant-select-arrow, .ant-select-clear {
        color: ${props => props.forecolor};
        background-color: ${props => props.backcolor};
    }

    ::placeholder {
        color: ${props => props.forecolor};
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: ${props => props.forecolor};
    }

    ::-ms-input-placeholder {
        color: ${props => props.forecolor};
    }
`;

const CustomSelect: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledSelect dropdownClassName="custom-select-list" allowClear {...theme} {...props}>
            {props.children}
        </StyledSelect>
    );
}

export default CustomSelect;
