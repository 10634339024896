import { CustomDescription, PrimaryButton, SecondaryButton } from "components/common";
import { useTranslation } from "react-i18next";
import CustomResult from "../common/CustomResult";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { Routes } from "../../utils";
import {useRegistrationContext} from "../../contexts/registration/registrationContext";

export default function FailedPaymentView() {
    const { t } = useTranslation(["common", "failedPayment"]);
    const { data } = useRegistrationContext();
    const { funding } = data?.selectedRegistration || {};
    
    return (
        <>
            <CustomResult
                status="error"
                title={t("failedPayment:TITLE")}
            />
            <CustomDescription>
                {t("failedPayment:DESCRIPTION")}
            </CustomDescription>
            <Row>
                <Col span={24}>
                    <Link to={Routes.HOME_PAGE} style={{ marginRight: 25 }}>
                        <SecondaryButton style={{ marginTop: 50 }}>
                            {t("failedPayment:COME_BACK_HOME_PAGE")}
                        </SecondaryButton>
                    </Link>
                    <PrimaryButton style={{ marginTop: 50 }}>
                        {t("failedPayment:PAY_AGAIN")} ({(funding?.price || 0).toFixed(2)} PLN)
                    </PrimaryButton>
                </Col>
            </Row>
        </>
    );
}
