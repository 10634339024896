import { BasicProps } from 'antd/lib/layout/layout';
import { useThemeContext } from 'contexts/theme';
import React from 'react';
import styled from 'styled-components';
import { constants } from 'utils';

interface IProps extends BasicProps {}

const StyledParagraph = styled.p<IProps & IAccessibility>`
    font-size: 1rem;

    &, &:hover, &:active, &:focus {
        color: ${props => props.forecolor || constants.COLOR.TEXT};
    }
`;

const CustomDescription: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledParagraph {...theme} {...props}>
            {props.children}
        </StyledParagraph>
    );
}

export default CustomDescription;
