import { Form } from 'antd'
import { CustomFormItem, CustomInput, CustomMaskedInput } from 'components/common';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { validateBirthDate } from 'utils/helpers';
import { customValidator } from 'utils/validators';

type Props = {
  initialValues: any;
  buttons: React.ReactElement;
  onFinish: (request: VerifyInvitation) => void;
}

const InvitationForm: React.FC<Props> = ({ buttons, initialValues, onFinish }) => {
  const { t } = useTranslation();

  return (
    <Form onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <CustomFormItem
          name="accessCode"
          label={t("invitation:ACCESS_CODE_LABEL")}
          rules={[
            {
              required: true,
              message: t("common:REQUIRED_FIELD")
            }
          ]}
        >
          <CustomInput size="large" maxLength={8} style={{ maxWidth: 300 }} />
        </CustomFormItem>

        <CustomFormItem
          name="birthAt"
          label={t("invitation:BIRTH_DATE_LABEL")}
          rules={[
            {
              required: true,
              message: t("common:REQUIRED_FIELD")
            },
            {
              validator: (_, value) => customValidator(validateBirthDate, value, true, t("common:INVALID_BIRTH_DATE"))
            },
          ]}
        >
          <CustomMaskedInput mask='11.11.1111' size="large" style={{ maxWidth: 300, marginBottom: 0 }} />
        </CustomFormItem>

        {buttons}        
      </Form>
  )
}

export default InvitationForm