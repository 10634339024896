import { Button, ButtonProps } from 'antd';
import { useThemeContext } from 'contexts/theme';
import React from 'react';
import styled from 'styled-components';
import { constants } from 'utils';

interface IProps extends ButtonProps {}

const StyledButton = styled(Button)<IProps & IAccessibility>`
    font-size: 1rem;
    line-height: 1rem;
    white-space: pre-line;

    &, &:hover, &:active, &:focus {
        color: ${props => props.backcolor || constants.COLOR.BACKGROUND};
        background-color: ${props => props.forecolor || constants.COLOR.PRIMARY};
        border: 2px solid ${props => props.forecolor || constants.COLOR.PRIMARY};
        border-radius: 5px;
        outline: none;
    }

    &:hover {
        background-color: ${props => props.forecolor || constants.COLOR.PRIMARY_DARKEN};
        border-color: ${props => props.forecolor || constants.COLOR.PRIMARY_DARKEN};
    }

    @media screen and (max-width: 992px) {
        margin: 10px 0px;
        width: 100%;
        height: auto;
    }
`;

const PrimaryButton: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledButton size="large" {...theme} {...props}>
            {props.children}
        </StyledButton>
    );
}

export default PrimaryButton;
