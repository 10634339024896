import { BasicProps } from "antd/lib/layout/layout";
import { useThemeContext } from "contexts/theme";
import styled from "styled-components";
import { constants } from "utils";

interface IProps extends BasicProps {}

const StyledHeader = styled.h1<IProps & IAccessibility>`
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: ${props => props.forecolor || constants.COLOR.PRIMARY};
`;

const Header: React.FC<IProps> = (props) => {
    const { theme } = useThemeContext();

    return (
        <StyledHeader {...theme} {...props}>
            {props.children}
        </StyledHeader>
    );
}

export default Header;
