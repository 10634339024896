import { useEffect, useRef, useState } from "react";

import { useUnmount } from "ahooks";
import { useTranslation } from "react-i18next";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";

import { QueueView } from "components/views";
import { displayWarningNotification } from "utils/notifications";
import { constants, Routes } from "utils";
import { PrimaryButton } from "../../common";
import { queue as dal } from "dal";

const QueuedPath: React.FC<RouteProps> = props => {
    const { t } = useTranslation(["queue"]);
    const history = useHistory();

    const [hasGetInitialInformation, setGetInitialInformation] = useState(false);
    const [interval, setIntervalTimer] = useState<any>(null);
    const [keepAliveInterval, setKeepAliveIntervalTimer] = useState<any>(null);
    const [timeout, setTimeoutTimer] = useState<any>(null);
    const [allowReconnectSession, setAllowReconnectSession] = useState(false);
    const [queue, setQueue] = useState<IQueueDetails>({
        peopleInQueue: 0,
        maximumWaitingTime: 0,
        permissions: 1
    });

    const connectionRef = useRef<HubConnection | null>(null);

    const handleFailedSocketConnection = () => {
        setAllowReconnectSession(true);

        displayWarningNotification({
            message: t("queue:CANNOT_CONNECT_TO_SOCKET")
        });
    };

    const stopSocketConnection = () => {
        connectionRef?.current?.stop();
    };

    useUnmount(() => {
        stopSocketConnection();
    });

    const setSessionConnection = () => {
        connectionRef.current = new HubConnectionBuilder().withUrl("/hubs/queue").withAutomaticReconnect().build();
    };

    const reconnectToSession = () => {
        if (connectionRef) {
            connectionRef.current?.stop().then(setSessionConnection);
        }
    };

    const keepSessionAlive = () => {
        const keepAliveTimer = setInterval(async () => {
            await dal.keepQueueSessionAlive(connectionRef?.current?.connectionId || "");
        }, 10_000);
        setKeepAliveIntervalTimer(keepAliveTimer);
    };

    useEffect(() => {
        setSessionConnection();
        keepSessionAlive();

        return () => {
            clearInterval(interval);
            clearInterval(keepAliveInterval);
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (interval) {
            clearInterval(interval);
        }

        const timer = setInterval(() => {
            setQueue(prevState => ({
                ...prevState,
                maximumWaitingTime: prevState.maximumWaitingTime > 0 ? prevState.maximumWaitingTime - 1 : 0
            }));
        }, 1000);

        setIntervalTimer(timer);
    }, [queue.peopleInQueue]);

    useEffect(() => {
        if (connectionRef && connectionRef.current?.state === "Disconnected") {
            connectionRef.current
                ?.start()
                .then(() => {
                    setGetInitialInformation(true);

                    connectionRef.current?.on("Synchronize", (message: IQueueDetails) => {
                        setQueue({
                            ...message,
                            maximumWaitingTime: message.peopleInQueue > 0 ? message.peopleInQueue * constants.TIMEOUTS.MAXIMUM_TIME_FOR_SELECT_STUDY_DATE : 0
                        });
                    });

                    connectionRef.current?.on("Disconnect", () => {
                        if (history.location.pathname.includes(Routes.QUEUE)) {
                            history.push(Routes.FORM_VIEW);
                        }
                    });
                })
                .catch(handleFailedSocketConnection);
        }
    }, [connectionRef.current?.state]);

    const validateSocketConnection = () => {
        setAllowReconnectSession(true);
    };

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout);
        }

        const timer = setTimeout(() => validateSocketConnection(), 3000);
        setTimeoutTimer(timer);
    }, [connectionRef.current?.state]);

    const getMaxiumWaitingTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.ceil(time % 60);

        return `${minutes > 0 ? `${minutes}m` : ""} ${`${seconds}s`}`;
    };

    if (connectionRef.current?.state !== "Connected" || !hasGetInitialInformation) {
        return (
            <QueueView displayAdditionalInformation={false}>
                {allowReconnectSession && queue.peopleInQueue === 0 && (
                    <PrimaryButton onClick={() => reconnectToSession()}>{t("queue:RECONNECT")}</PrimaryButton>
                )}
            </QueueView>
        );
    }

    if (connectionRef.current?.state === "Connected" && queue.permissions === 1) {
        return (
            <QueueView
                displayAdditionalInformation={true}
                peoplesInQueue={queue.peopleInQueue}
                maximumWaitingTime={getMaxiumWaitingTime(queue.maximumWaitingTime)}
            >
                {allowReconnectSession && queue.peopleInQueue === 0 && (
                    <PrimaryButton onClick={() => reconnectToSession()}>{t("queue:RECONNECT")}</PrimaryButton>
                )}
            </QueueView>
        );
    }

    return <Route {...props}>{props.children}</Route>;
};

export default QueuedPath;
