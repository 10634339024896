import { Row, Form, Col, notification } from "antd";
import {
    CustomCard,
    CustomDescription,
    CustomFormItem,
    Header,
    LinkButton,
    PrimaryButton,
    SecondaryButton,
    Upload
} from "components/common";
import CustomInput from "components/common/CustomInput";
import { useRegistrationContext } from "contexts/registration/registrationContext";
import { useTranslation } from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {constants, Routes} from "utils";
import {getDate, hasPhoneNumber} from "utils/helpers";
import * as dal from 'dal'
import { IReferralFormSubmitValues } from "typings/referral";
import { RcFile } from "antd/lib/upload";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useState} from "react";
import {interceptApiError} from "../../../utils/interceptors";

interface IProps {
    firstName: string;
    lastName: string;
    studyDate?: string;
    allowBackToPersonalData?: boolean;
    allowMakePhoneRequest?: boolean;
    onFinish: (referralId: string) => void;
    onCancel?: () => void;
}

export default function UploadFile({ firstName, lastName, studyDate, allowBackToPersonalData, allowMakePhoneRequest, onFinish, onCancel }: IProps) {
    const { t } = useTranslation(["common", "referral"]);
    const { data, dispatch } = useRegistrationContext();
    const history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [form] = Form.useForm();
    const [isUploading, setUploading] = useState(false);
    const [fileName, setFileName] = useState("");

    const handleSuccessfulReferralSubmit = (data: any) => {
        onFinish(data?.id);
    }
    
    const uplaodFile = ({ files }: IReferralFormSubmitValues) => {
        if(files.length <= 0 && files.length > 1)
            return notification.warn({ message: "Należy załączyć skierowanie"})

        const { personalData: { pesel, idCard, passport } } = data;
        const originFileList = files.map(el => el.originFileObj)[0] as RcFile;

        const formData = new FormData();

        formData.append("file", originFileList);

        setUploading(true);
        
        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.UPLOAD_REFERRAL)
            .then(token => {
                formData.append("token", token);

                dal.referral
                    .add(formData)
                    .then(handleSuccessfulReferralSubmit)
                    .catch(error => interceptApiError(error, t, history))
                    .finally(() => setUploading(false));
            })
            .catch(() => setUploading(false))
    };

    const onFileAdd = (files: any) => {
        setFileName(files?.[0]?.name || "");
        form.setFieldsValue({ files });
    }

    return (
        <>
            <Header>{t("referral:TITLE")}</Header>
            <CustomDescription>{t("referral:DESCRIPTION")}</CustomDescription>
            <CustomCard title={t("referral:DETAILS")} style={{ marginTop: 50 }}>
                <Row>
                    <Col span={24}>
                        <CustomDescription>
                            <b>{t("referral:PATIENT_NAME")}:</b> {firstName} {lastName}
                        </CustomDescription>
                        {studyDate && (
                            <CustomDescription>
                                <b>{t("referral:STUDY_DATE")}:</b> {getDate(studyDate)}
                            </CustomDescription>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form
                            form={form}
                            onFinish={uplaodFile}
                            layout="vertical"
                            style={{
                                marginTop: 50
                            }}
                        >
                            <CustomFormItem name="files" label={t("referral:FORM_SCAN")} rules={[{ required: true, message: t("common:REQUIRED_FIELD") }]}>
                                <Upload.ReferralUpload handleChange={onFileAdd}>
                                    <CustomInput placeholder={t("referral:FILE_INPUT_PLACEHOLDER")} value={fileName} style={{ width: 600, maxWidth: "100%" }} />
                                </Upload.ReferralUpload>
                                <CustomDescription style={{ marginTop: 15, marginBottom: 0 }}>
                                    {t("referral:UPLOAD_FILE_TYPE_INFO")}
                                </CustomDescription>
                            </CustomFormItem>
                        </Form>
                    </Col>
                </Row>
            </CustomCard>
            <Row style={{ marginTop: 50 }}>
                <Col span={24}>
                    {allowBackToPersonalData && (
                        <SecondaryButton onClick={onCancel} style={{ marginRight: 25 }}>{t("common:BACK_AND_CHANGE_PERSONAL_DATA")}</SecondaryButton>
                    )}
                    {allowMakePhoneRequest && hasPhoneNumber(data.personalData?.phone) && (
                        <Link to={Routes.PHONE_CONTACT}>
                            <SecondaryButton style={{ marginRight: 25 }}>{t("common:REQUEST_PHONE_CALL")}</SecondaryButton>
                        </Link>
                    )}
                    <PrimaryButton
                        loading={isUploading}
                        htmlType="submit"
                        onClick={() => form.submit()}
                    >
                        {t("referral:CONFIRM")}
                    </PrimaryButton>
                </Col>
            </Row>
        </>
    );
}
