import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Routes } from 'utils';

const invitationLinkRegex = /\/[a-z0-9]{8}$/;

const { pathname } = window.location

const useInvitationLink = () => {
  const { push } = useHistory();

  useEffect(() => {
    if (invitationLinkRegex.test(pathname)) {
      push(Routes.INVITATION);
    }
  }, [pathname, push]);
}

export default useInvitationLink;
