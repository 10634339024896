export enum Routes {
    HOME_PAGE = "/",
    FORM_VIEW = "/2",
    QUESTIONNAIRE = "/3",
    STUDY_DATE = "/4",
    CONFIRM_STUDY_DATE = "/5",
    REFERRAL = "/6",
    PHONE_CONTACT = "/7",
    QUEUE = "/queue",
    SUCCESSFUL_PATIENT_REGISTRATION = "/patient-registration",
    SUCCESSFUL_PATIENT_PAYMENT = "/patient-payment-success",
    FAILED_PATIENT_PAYMENT = "/patient-payment-failed",
    SUCCESSFUL_REFERRAL_UPLOAD = "/successful-referal-upload",
    INVITATION = "/invitation"
};
