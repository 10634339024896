import { Col, Form, Row } from "antd";
import {
    CustomCard,
    CustomDescription,
    CustomFormItem,
    CustomFuncModal,
    CustomMaskedInput,
    CustomModal,
    Header,
    PrimaryButton,
    SecondaryButton
} from "components/common";
import { StudyDateCounter } from "components/features/counter";
import { useTranslation } from "react-i18next";
import { hasPhoneNumber } from "utils/helpers";
import { Link, useHistory } from "react-router-dom";
import { useRegistrationContext } from "../../contexts/registration/registrationContext";
import { capitalizeFirstLetter, getDate, getDayName } from "../../utils/helpers";
import { useEffect, useState } from "react";
import * as dal from "../../dal";
import { AxiosError } from "axios";
import { constants, Routes } from "utils";
import { interceptApiError } from "../../utils/interceptors";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import dayjs, { Dayjs } from "dayjs";

export default function ConfirmStudyDateView() {
    const history = useHistory();
    const { t } = useTranslation(["common", "confirmStudyDate"]);
    const { data } = useRegistrationContext();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [render, setRender] = useState(false);
    const [dateDeadline, setDateDeadline] = useState<Dayjs | null>(null);
    const [displaySmsConfirmation, setSmsConfirmation] = useState(false);
    const [isRegisteringPatient, setPatientRegistering] = useState(false);
    const [isSendingConfirmationCode, setSendingConfirmationCode] = useState(false);

    const { funding, campaignRegistration } = data?.selectedRegistration || {};
    const { personalData } = data || {};

    const getDeadlineDifference = () => dayjs(dateDeadline).diff(dayjs(), "seconds");

    useEffect(() => {
        const timer = setInterval(() => setRender(prevState => !prevState), 1000);

        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.BLOCK_SELECTED_DATE).then(token => {
            dal.screening
                .blockSindgleCampaignRegistration(data.selectedRegistration?.campaignRegistration?.token || "", token)
                .then(() => setDateDeadline(dayjs().add(constants.TIMEOUTS.MAXIMUM_TIME_TO_CONFIRM_STUDY_DATE, "seconds")))
                .catch(error => interceptApiError(error, t, history));
        });

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const time = getDeadlineDifference();
        if (dateDeadline === null || isNaN(time)) return;

        if (time <= 0) {
            history.push(Routes.FORM_VIEW);
        }
    }, [render]);

    const handleFailedSendConfirmationCode = (error: AxiosError) => {
        if (error.response?.data === "INVALID_CAMPAIGN_REGISTRATION") {
            CustomFuncModal.displayConfirm({
                title: t("confirmStudyDate:CONFIRMATION_ERROR"),
                content: t("confirmStudyDate:CAMPAIGN_NOT_FOUND"),
                cancelText: t("confirmStudyDate:CLOSE"),
                okText: t("confirmStudyDate:BACK_TO_DATES_LIST"),
                onOk: () => history.push(Routes.STUDY_DATE)
            });
        }

        if (error.response?.data === "PATIENT_REGISTERED") {
            CustomFuncModal.displayConfirm({
                title: t("confirmStudyDate:DATE_NOT_AVAILABLE"),
                content: t("confirmStudyDate:DATE_NOT_AVAILABLE_INFO"),
                cancelText: t("confirmStudyDate:CLOSE"),
                okText: t("confirmStudyDate:BACK_TO_DATES_LIST"),
                onOk: () => history.push(Routes.STUDY_DATE)
            });
        }

        if (error.response?.data === "INVALID_REGISTRATION_CODE") {
            CustomFuncModal.displayConfirm({
                title: t("confirmStudyDate:BAD_CONFIRMATION_CODE"),
                content: t("confirmStudyDate:BAD_CONFIRMATION_CODE_INFO"),
                cancelText: t("confirmStudyDate:CLOSE"),
                okText: t("confirmStudyDate:GENERATE_NEW_CODE"),
                onOk: () => sendConfirmationCode()
            });
        }

        if (error.response?.data === "CODE_HAS_BEEN_USED") {
            CustomFuncModal.displayConfirm({
                title: t("confirmStudyDate:CONFIRMATION_CODE_IS_USED"),
                content: t("confirmStudyDate:CONFIRMATION_CODE_IS_USED_INFO"),
                cancelText: t("confirmStudyDate:CLOSE"),
                okText: t("confirmStudyDate:GENERATE_NEW_CODE"),
                onOk: () => sendConfirmationCode()
            });
        }

        if (error.response?.data === "CODE_HAS_EXPIRED") {
            CustomFuncModal.displayConfirm({
                title: t("confirmStudyDate:CONFIRMATION_CODE_EXPIRED"),
                content: t("confirmStudyDate:CONFIRMATION_CODE_EXPIRED_INFO"),
                cancelText: t("confirmStudyDate:CLOSE"),
                okText: t("confirmStudyDate:GENERATE_NEW_CODE"),
                onOk: () => sendConfirmationCode()
            });
        }
    };

    const sendConfirmationCode = () => {
        setSendingConfirmationCode(true);

        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.CONFIRM_DATE)
            .then(token => {
                dal.screening
                    .sendConfirmationCode(data.selectedRegistration?.campaignRegistration?.registrationId || 0, data.personalData?.phone || "", token)
                    .then(() => setSmsConfirmation(true))
                    .catch(handleFailedSendConfirmationCode)
                    .finally(() => setSendingConfirmationCode(false));
            })
            .catch(() => setSendingConfirmationCode(false));
    };

    const registerNewPatient = (values: any) => {
        setPatientRegistering(true);

        executeRecaptcha?.(constants.CAPTCHA_ACTIONS.SEND_CONFIRMATION_CODE)
            .then(token => {
                const { personalData, selectedRegistration } = data || {};

                dal.screening
                    .confirmRegistration(
                        data.selectedRegistration?.campaignRegistration?.registrationId || 0,
                        values.code || "",
                        data.personalData,
                        data.referralId || "",
                        selectedRegistration?.funding?.isPaid || false,
                        data.questionnaire || "",
                        token
                    )
                    .then(() => history.push(Routes.SUCCESSFUL_PATIENT_REGISTRATION))
                    .catch(handleFailedSendConfirmationCode)
                    .finally(() => setPatientRegistering(false));
            })
            .catch(() => setPatientRegistering(false));
    };

    return (
        <>
            <Header>{t("confirmStudyDate:TITLE")}</Header>
            <CustomDescription>{t("confirmStudyDate:DESCRIPTION")}</CustomDescription>
            <CustomCard
                title={t("confirmStudyDate:DETAILS")}
                style={{ marginTop: 50 }}
                extra={<StudyDateCounter time={getDeadlineDifference()}>{t("confirmStudyDate:TIME_FOR_DECISION")}:</StudyDateCounter>}
            >
                <CustomDescription>
                    <b>{t("confirmStudyDate:FINANCING_TYPE")}:</b> {t(funding?.isPaid ? "confirmStudyDate:PAID_DATES" : "confirmStudyDate:REIMBURSER_DATES")}{" "}
                    {funding?.isPaid ? `${(funding?.price || 0).toFixed(2)} PLN` : funding?.name}
                </CustomDescription>
                <CustomDescription>
                    <b>{t("confirmStudyDate:PRICE")}:</b> {funding?.isPaid ? (funding?.price || 0).toFixed(2) : 0.0} PLN (
                    {t(funding?.isPaid ? "confirmStudyDate:PAID_DATES" : "confirmStudyDate:REIMBURSER_DATES")}{" "}
                    {funding?.isPaid ? `${(funding?.price || 0).toFixed(2)} PLN` : funding?.name})
                </CustomDescription>
                <CustomDescription>
                    <b>{t("confirmStudyDate:DATE")}:</b> {getDate(campaignRegistration?.date || "")} (
                    {capitalizeFirstLetter(getDayName(campaignRegistration?.date || ""))}) {campaignRegistration?.startAt?.hours}:
                    {(campaignRegistration?.startAt?.minutes || 999) < 10
                        ? `0${campaignRegistration?.startAt?.minutes}`
                        : campaignRegistration?.startAt?.minutes}
                </CustomDescription>
                <CustomDescription>
                    <b>{t("confirmStudyDate:LOCATION")}:</b> {capitalizeFirstLetter(campaignRegistration?.voivodeship || "")}, {campaignRegistration?.community}
                    , {campaignRegistration?.shortName}
                </CustomDescription>
            </CustomCard>
            <CustomCard title={t("confirmStudyDate:YOUR_DATA")} style={{ marginTop: 50 }}>
                <CustomDescription>
                    <b>{t("confirmStudyDate:FIRST_NAME")}:</b> {personalData.firstName}
                </CustomDescription>
                <CustomDescription>
                    <b>{t("confirmStudyDate:LAST_NAME")}:</b> {personalData.lastName}
                </CustomDescription>
                <CustomDescription>
                    <b>{t("confirmStudyDate:EMAIL")}:</b> {personalData.email}
                </CustomDescription>
                <CustomDescription>
                    <b>{t("confirmStudyDate:PHONE_NUMBER")}:</b> {personalData.phone}
                </CustomDescription>
                <CustomDescription>
                    <b>{t("confirmStudyDate:ID")}:</b> {personalData.pesel || personalData.idCard || personalData.passport}
                </CustomDescription>
            </CustomCard>
            <Row style={{ marginTop: 50 }}>
                <Col span={24}>
                    <Link to={Routes.FORM_VIEW}>
                        <SecondaryButton style={{ marginRight: 25 }}>{t("common:BACK_AND_CHANGE_PERSONAL_DATA")}</SecondaryButton>
                    </Link>
                    {hasPhoneNumber(data.personalData?.phone) && (
                        <Link to={Routes.PHONE_CONTACT}>
                            <SecondaryButton style={{ marginRight: 25 }}>{t("common:REQUEST_PHONE_CALL")}</SecondaryButton>
                        </Link>
                    )}
                    <Link to={Routes.CONFIRM_STUDY_DATE}>
                        <PrimaryButton loading={isSendingConfirmationCode} htmlType="submit" onClick={sendConfirmationCode}>
                            {t("confirmStudyDate:CONFIRM")}
                        </PrimaryButton>
                    </Link>
                </Col>
            </Row>
            <CustomModal
                title={t("confirmStudyDate:CONFIRM_YOUR_RESERVATION")}
                visible={displaySmsConfirmation}
                footer={null}
                onCancel={() => setSmsConfirmation(false)}
                width={800}
                destroyOnClose
            >
                <CustomDescription>{t("confirmStudyDate:CONFIRM_YOUR_RESERVATION_INFO")}</CustomDescription>
                <Form layout="vertical" onFinish={registerNewPatient}>
                    <CustomFormItem
                        label={t("confirmStudyDate:CONFIRMATION_CODE")}
                        name="code"
                        style={{
                            marginTop: 50
                        }}
                        rules={[{ required: true, message: t("common:REQUIRED_FIELD") }]}
                    >
                        <CustomMaskedInput mask="111111" />
                    </CustomFormItem>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <PrimaryButton loading={isRegisteringPatient} htmlType="submit">
                                {t("confirmStudyDate:CONFIRM_RESERVATION")}
                            </PrimaryButton>
                        </Col>
                        <Col>
                            <StudyDateCounter time={getDeadlineDifference()}>{t("confirmStudyDate:TIME_FOR_DECISION")}:</StudyDateCounter>
                        </Col>
                    </Row>
                </Form>
            </CustomModal>
        </>
    );
}
